import { createContext } from 'react';
import englishText from './englishText.json';
import englishBlogText from './englishBlogText.json';

const LanguageContext = createContext({
  activeLanguage: 'English',
  blogText: englishBlogText,
  setLanguage: function () {},
  text: englishText,
});

export default LanguageContext;
