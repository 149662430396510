import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NAVBAR_ELEMENT_ID, SCROLL_CONTENT_ELEMENT_ID } from '../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: whitesmoke;
  color: #80824b;
  overflow-x: hidden;
  user-select: none;
`;

function Page({ children, className }) {
  const ref = useRef(null);

  useEffect(() => {
    const navbarElement = document.getElementById(NAVBAR_ELEMENT_ID);
    const scrollElement = ref.current;
    if (navbarElement === null || scrollElement === null) return;

    const handler = () => {
      const height = navbarElement.getBoundingClientRect().height ?? 0;
      scrollElement.style.top = `${height}px`;
    };

    const height = navbarElement.getBoundingClientRect().height ?? 0;
    scrollElement.style.top = `${height}px`;

    navbarElement.addEventListener('transitionend', handler);
    scrollElement.addEventListener('scroll', handler);
    return () => {
      navbarElement.removeEventListener('transitionend', handler);
      scrollElement.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <Container className={className} id={SCROLL_CONTENT_ELEMENT_ID} ref={ref}>
      {children}
    </Container>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default Page;
