import React from 'react';
import './Homepage.mobile.scss';
import NavbarMobile from '../../blocks/Navbar.mobile';
import HomepageIntroMobile from './sections-mobile/HomepageIntro.mobile';
import HomepageWhyCoachingMobile from './sections-mobile/HomepageWhyCoaching.mobile';
import HomepageTestimonialsMobile from './sections-mobile/HomepageTestimonials.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import Page from '../../blocks/Page';

function HomepageMobile() {
  return (
    <Page className="homepage-mobile">
      <NavbarMobile />
      <div className="page-content-mobile">
        <div className="text-content">
          <HomepageIntroMobile />
          <HomepageWhyCoachingMobile />
          <HomepageTestimonialsMobile />
        </div>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default HomepageMobile;
