import React from 'react';
import './HomepageIntro.scss';
import BorderedImage from '../../../blocks/BorderedImage';
import useLanguage from '../../../languages/useLanguage';
import getPathForRoute from '../../../helpers/getPathForRoute';
import { ROUTE_WORK_WITH_ME } from '../../../router';

function HomepageIntro() {
  const language = useLanguage();
  const workWithMeLink = getPathForRoute(ROUTE_WORK_WITH_ME);

  return (
    <div className="introduction">
      <div className="introduction-text">
        <div className="intro-title">
          <div>{language.homeIntroTitle}</div>
        </div>
        <div className="intro-description">
          <div>{language.homeIntroText1}</div>
          <br />
          <div>{language.homeIntroText2}</div>
          <br />
          <div className="intro-description-hover">
            <div>{language.homeIntroText3}</div>
            <a className="intro-description-hover-text" href={workWithMeLink} rel="noreferrer">
              {language.homeIntroTextBoldHover}
            </a>
            <div>{language.homeIntroText4}</div>
          </div>
        </div>
      </div>
      <div className="intro-image">
        <BorderedImage borderColor="#d4b466" image="/images/portrait.png" />
      </div>
    </div>
  );
}

export default HomepageIntro;
