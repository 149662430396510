import React from 'react';
import './TestimonialsFullpage.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import useLanguage from '../../languages/useLanguage';
import TestimonialCard from '../../blocks/TestimonialCard';
import ContentBox from '../../blocks/ContentBox';
import Page from '../../blocks/Page';

function TestimonialsFullpage() {
  const language = useLanguage();

  return (
    <Page className="testimonials-fullpage">
      <Navbar />
      <div className="testimonials-fullpage-content">
        <ContentBox side="right" title={language.testimonialsFullpageTitle}>
          <br />
          <div className="content">
            <TestimonialCard
              color="#999b6f"
              name="Jean-Baptiste"
              image="/images/jeanbaptiste.jpg"
              location={language.countryFrance}
            >
              <span>{language.testimonialsFullpageJB1}</span>
              <span>{language.testimonialsFullpageJB2}</span>
              <span>{language.testimonialsFullpageJB3}</span>
              <span>{language.testimonialsFullpageJB4}</span>
            </TestimonialCard>
            <TestimonialCard
              color="#cccdb7"
              name="Sarah"
              image="/images/sarah.jpg"
              location={language.countryNetherlands}
            >
              <span>{language.testimonialsFullpageSarah1}</span>
              <span>{language.testimonialsFullpageSarah2}</span>
              <span>{language.testimonialsFullpageSarah3}</span>
            </TestimonialCard>
            <TestimonialCard color="#b3b493" name="Sia" image="/images/sia.jpg" location={language.countryUK}>
              <span>{language.testimonialsFullpageSia1}</span>
              <span>{language.testimonialsFullpageSia2}</span>
              <span>{language.testimonialsFullpageSia3}</span>
              <span>{language.testimonialsFullpageSia4}</span>
            </TestimonialCard>
            <TestimonialCard
              color="#cccdb7"
              name="Katherina"
              image="/images/katherina.jpg"
              location={language.countryNetherlands}
            >
              <span>{language.testimonialsFullpageKatherina1}</span>
              <span>{language.testimonialsFullpageKatherina2}</span>
              <span>{language.testimonialsFullpageKatherina3}</span>
              <span>{language.testimonialsFullpageKatherina4}</span>
            </TestimonialCard>
            <TestimonialCard
              color="#b3b493"
              name="Christina"
              image="/images/christina.jpg"
              location={language.countryUK}
            >
              <span>{language.testimonialsFullpageChristina1}</span>
              <span>{language.testimonialsFullpageChristina2}</span>
              <span>{language.testimonialsFullpageChristina3}</span>
            </TestimonialCard>
            <TestimonialCard
              color="#999b6f"
              name="Franzi"
              image="/images/franzi.jpg"
              location={language.countryGermany}
            >
              <span>{language.testimonialsFullpageFranzi1}</span>
              <span>{language.testimonialsFullpageFranzi2}</span>
              <span>{language.testimonialsFullpageFranzi3}</span>
            </TestimonialCard>
            <TestimonialCard
              color="#cccdb7"
              name="Simon"
              image="/images/simon.jpg"
              location={language.countryNetherlands}
            >
              <span>{language.testimonialsFullpageSimon1}</span>
              <span>{language.testimonialsFullpageSimon2}</span>
            </TestimonialCard>
          </div>
          <br />
          <br />
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

export default TestimonialsFullpage;
