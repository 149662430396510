import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useWizardResize from '../../../hookers/useWizardResize';
import ContentTransitioner from './ContentTransitioner';

const Container = styled.div`
  position: relative;
  height: 100vh;

  &.animating {
    transition: max-height 0.5s ease-in-out;
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  right: 0;
  width: 78vw;

  overflow: hidden;

  &.animating {
    transition: top 0.5s ease-in-out, max-height 0.5s ease-in-out;
  }
`;

function Wizard({ children, className, step }) {
  const ref = useRef(null);
  const childrenRef = useRef(children);
  const [fullscreen, setFullscreen] = useState(false);
  const [content, setContent] = useState(children);
  const wizardResize = useWizardResize(fullscreen, ref);

  const contentStep =
    !fullscreen || wizardResize.isAnimating || wizardResize.contentStyle.position === 'relative' ? 0 : step;

  useEffect(() => {
    childrenRef.current = children;
  }, [children]);

  useEffect(() => {
    if (contentStep > 0 && fullscreen && !wizardResize.isAnimating) {
      setContent(childrenRef.current);
    }
  }, [contentStep, fullscreen, wizardResize.isAnimating]);

  useEffect(() => {
    setFullscreen(step !== 0);
  }, [step]);

  return (
    <Container className={classNames(className, wizardResize.className)} style={wizardResize.containerStyle}>
      <Content className={wizardResize.className} ref={ref} style={wizardResize.contentStyle}>
        <ContentTransitioner step={contentStep}>{content}</ContentTransitioner>
      </Content>
    </Container>
  );
}

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  step: PropTypes.number.isRequired,
};

export default Wizard;
