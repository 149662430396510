import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Input = styled.input`
  position: absolute;
  pointer-events: none;
  z-index: -2;
`;

function HiddenRadioInput({ id, name, value }) {
  return <Input className="hidden" id={id} name={name} required type="radio" value={value} />;
}

HiddenRadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default HiddenRadioInput;
