import React from 'react';
import './TestimonialsFullpage.mobile.scss';
import useLanguage from '../../languages/useLanguage';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';
import Page from '../../blocks/Page';

function TestimonialsFullpageMobile() {
  const language = useLanguage();

  return (
    <Page className="testimonials-fullpage-mobile">
      <NavbarMobile />
      <div className="testimonials-fullpage-mobile-content">
        <ContentBoxMobile side="right" title={language.testimonialsFullpageTitle}>
          <div className="content-mobile">
            <div className="jean-baptiste">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-photo">
                  <img src="/images/jeanbaptiste.jpg" alt="jeanbaptiste" />
                </div>
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Jean-Baptiste</div>
                  <div className="testimonial-country">{language.countryFrance}</div>
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageJB1}</div>
                <div>{language.testimonialsFullpageJB2}</div>
                <div>{language.testimonialsFullpageJB3}</div>
                <div>{language.testimonialsFullpageJB4}</div>
              </div>
            </div>

            <div className="sarah">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Sarah</div>
                  <div className="testimonial-country">{language.countryNetherlands}</div>
                </div>
                <div className="testimonial-photo">
                  <img src="/images/sarah.jpg" alt="sarah" />
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageSarah1}</div>
                <div>{language.testimonialsFullpageSarah2}</div>
                <div>{language.testimonialsFullpageSarah3}</div>
              </div>
            </div>

            <div className="sia">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-photo">
                  <img src="/images/sia.jpg" alt="sia" />
                </div>
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Sia</div>
                  <div className="testimonial-country">{language.countryUK}</div>
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageSia1}</div>
                <div>{language.testimonialsFullpageSia2}</div>
                <div>{language.testimonialsFullpageSia3}</div>
                <div>{language.testimonialsFullpageSia4}</div>
              </div>
            </div>

            <div className="katherina">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Katherina</div>
                  <div className="testimonial-country">{language.countryNetherlands}</div>
                </div>
                <div className="testimonial-photo">
                  <img src="/images/katherina.jpg" alt="katherina" />
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageKatherina1}</div>
                <div>{language.testimonialsFullpageKatherina2}</div>
                <div>{language.testimonialsFullpageKatherina3}</div>
                <div>{language.testimonialsFullpageKatherina4}</div>
              </div>
            </div>

            <div className="christina">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-photo">
                  <img src="/images/christina.jpg" alt="christina" />
                </div>
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Christina</div>
                  <div className="testimonial-country">{language.countryUK}</div>
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageChristina1}</div>
                <div>{language.testimonialsFullpageChristina2}</div>
                <div>{language.testimonialsFullpageChristina3}</div>
              </div>
            </div>

            <div className="franzi">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Franzi</div>
                  <div className="testimonial-country">{language.countryGermany}</div>
                </div>
                <div className="testimonial-photo">
                  <img src="/images/franzi.jpg" alt="franzi" />
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageFranzi1}</div>
                <div>{language.testimonialsFullpageFranzi2}</div>
                <div>{language.testimonialsFullpageFranzi3}</div>
              </div>
            </div>

            <div className="simon">
              <div className="testimonial-photo-name-country">
                <div className="testimonial-photo">
                  <img src="/images/simon.jpg" alt="simon" />
                </div>
                <div className="testimonial-name-country">
                  <div className="testimonial-name">Simon</div>
                  <div className="testimonial-country">{language.countryNetherlands}</div>
                </div>
              </div>
              <div className="testimonial-text">
                <div>{language.testimonialsFullpageSimon1}</div>
                <div>{language.testimonialsFullpageSimon2}</div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </ContentBoxMobile>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default TestimonialsFullpageMobile;
