import React from 'react';
import PropTypes from 'prop-types';
import getPathForRoute from '../helpers/getPathForRoute';
import styled from 'styled-components';

const Anchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 3.5vh;
  margin: 3vh 0 1vh 0;
  width: 9vw;

  background-color: whitesmoke;
  border: 1px solid #80824b;
  border-radius: 10px 10px 10px 10px;
  color: #80824b;
  font-family: RalewayMedium, monospace;
  font-weight: bolder;
  font-size: 1vw;
  letter-spacing: 0.2vw;
  text-shadow: unset;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    background-color: #80824b;
    border: 1px solid whitesmoke;
    color: whitesmoke;
    text-shadow: unset;
  }
`;

const FlatButton = ({ className, href: providedHref, label, route }) => (
  <Anchor className={className} href={providedHref ?? getPathForRoute(route)} rel="noreferrer">
    {label}
  </Anchor>
);

FlatButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
  route: PropTypes.string,
};

export default FlatButton;
