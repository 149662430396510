import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ActionPlans.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import useLanguage from '../../languages/useLanguage';
import ContentBox from '../../blocks/ContentBox';
import BorderedImage from '../../blocks/BorderedImage';
import { PLAN_FLOW_ELEMENT_ID, PLAN_PLAN_ELEMENT_ID, PLAN_RESILIENCE_ELEMENT_ID } from '../../constants';
import Page from '../../blocks/Page';
import getPathForRoute from '../../helpers/getPathForRoute';
import { ROUTE_WORK_WITH_ME } from '../../router';

function ActionPlans({ jumpTo }) {
  const language = useLanguage();

  useEffect(
    function () {
      if (jumpTo !== undefined) {
        const element = document.getElementById(jumpTo);
        element?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [jumpTo],
  );

  return (
    <Page className="action-plans-page">
      <Navbar />
      <div className="action-plans-page-content">
        <ContentBox id={PLAN_RESILIENCE_ELEMENT_ID} side="right" title={language.actionPlanResilience}>
          <div className="action-plans-resilience">
            <div className="outcomes-list-items">
              <div className="list-item-1">{language.apResilienceOutcome1}</div>
              <div className="list-item-2">{language.apResilienceOutcome2}</div>
              <div className="list-item-3">{language.apResilienceOutcome3}</div>
              <div className="list-item-4">{language.apResilienceOutcome4}</div>
            </div>
            <div className="resilience-image-text">
              <div className="resilience-image">
                <BorderedImage borderColor="#d4b466" image="/images/quote-find-resilience.jpg" />
              </div>
              <div className="resilience-text-content">
                <div>{language.apResilienceText1}</div>
                <div>{language.apResilienceText2}</div>
                <div>{language.apResilienceText3}</div>
                <div>{language.apResilienceText4}</div>
                <div>{language.apResilienceText5}</div>
              </div>
            </div>
            <a className="resilience-conclusion" href={getPathForRoute(ROUTE_WORK_WITH_ME)} rel="noreferrer">
              <div>{language.apResilienceConclusion1}</div>
              <div>{language.apResilienceConclusion2}</div>
            </a>
          </div>
        </ContentBox>

        <ContentBox id={PLAN_PLAN_ELEMENT_ID} side="left" title={language.actionPlanHeadAhead}>
          <div className="action-plans-head-ahead">
            <div className="outcomes-list-items align-right">
              <div className="list-item-5">{language.apHeadAheadOutcome1}</div>
              <div className="list-item-6">{language.apHeadAheadOutcome2}</div>
              <div className="list-item-7">{language.apHeadAheadOutcome3}</div>
              <div className="list-item-8">{language.apHeadAheadOutcome4}</div>
            </div>
            <div className="head-ahead-image-text">
              <div className="head-ahead-text-content">
                <div>{language.apHeadAheadText1}</div>
                <div>{language.apHeadAheadText2}</div>
                <div>{language.apHeadAheadText3}</div>
                <div>{language.apHeadAheadText4}</div>
                <div>{language.apHeadAheadText5}</div>
              </div>
              <div className="head-ahead-image">
                <BorderedImage borderColor="#d4b466" image="/images/quote-head-ahead.jpg" />
              </div>
            </div>
            <a className="head-ahead-conclusion" href={getPathForRoute(ROUTE_WORK_WITH_ME)} rel="noreferrer">
              <div>{language.apHeadAheadConclusion}</div>
            </a>
          </div>
        </ContentBox>

        <ContentBox id={PLAN_FLOW_ELEMENT_ID} side="right" title={language.actionPlanFlow}>
          <div className="action-plans-flow">
            <div className="outcomes-list-items">
              <div className="list-item-9">{language.apFlowOutcome1}</div>
            </div>
            <div className="flow-image-text">
              <div className="flow-image">
                <BorderedImage borderColor="#d4b466" image="/images/quote-follow-your-flow.jpg" />
              </div>
              <div className="flow-text-content">
                <div>{language.apFlowText1}</div>
                <div>{language.apFlowText2}</div>
                <div>{language.apFlowText3}</div>
                <div>{language.apFlowText4}</div>
              </div>
            </div>
            <a className="flow-conclusion" href={getPathForRoute(ROUTE_WORK_WITH_ME)} rel="noreferrer">
              <div>{language.apFlowConclusion1}</div>
              <div>{language.apFlowConclusion2}</div>
            </a>
          </div>
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

ActionPlans.propTypes = {
  jumpTo: PropTypes.string,
};

export default ActionPlans;
