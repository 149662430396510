import React from 'react';
import './HomepageWhyCoaching.scss';
import BorderedImage from '../../../blocks/BorderedImage';
import useLanguage from '../../../languages/useLanguage';
import FlatButton from '../../../blocks/FlatButton';

function HomepageWhyCoaching() {
  const language = useLanguage();

  return (
    <div className="why-coaching-preview">
      <div className="why-coaching-preview-image">
        <BorderedImage borderColor="#d4b466" image="/images/why-coaching-03.png" />
      </div>
      <div className="why-coaching-preview-text">
        <div className="why-coaching-preview-title">{language.homeWhyCoachingTitle}</div>
        <div className="why-coaching-preview-description">
          <div>{language.homeWhyCoaching1}</div>
          <div>{language.homeWhyCoaching2}</div>
          <div>{language.homeWhyCoaching3}</div>
        </div>
        <FlatButton label={language.readMoreButton} route="about" />
      </div>
    </div>
  );
}

export default HomepageWhyCoaching;
