import React from 'react';
import './TermsAndConditions.mobile.scss';
import useLanguage from '../../languages/useLanguage';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import Page from '../../blocks/Page';

function TermsAndConditionsMobile() {
  const language = useLanguage();

  return (
    <Page className="terms-and-conditions-page-mobile">
      <NavbarMobile />
      <div className="terms-page-content-mobile">
        <div className="main-title">{language.termsAndConditionsTitle}</div>
        <div className="last-update">{language.termsAndConditionsUpdate}</div>

        <div className="subtitle">{language.termsAndConditionsUse}</div>
        <div className="text">
          <div>{language.termsAndConditionsUse1}</div>
          <div>{language.termsAndConditionsUse2}</div>
          <div>{language.termsAndConditionsUse3}</div>
          <div>{language.termsAndConditionsUse4}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsLogin}</div>
        <div className="text">
          <div>{language.termsAndConditionsLogin1}</div>
          <div>{language.termsAndConditionsLogin2}</div>
          <div>{language.termsAndConditionsLogin3}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsMaterials}</div>
        <div className="text">
          <div>{language.termsAndConditionsMaterials1}</div>
          <div>{language.termsAndConditionsMaterials2}</div>
          <div>{language.termsAndConditionsMaterials3}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsOtherUses}</div>
        <div className="text">
          <div>{language.termsAndConditionsOtherUses1}</div>
          <div>{language.termsAndConditionsOtherUses2}</div>
          <div>{language.termsAndConditionsOtherUses3}</div>
          <div>{language.termsAndConditionsOtherUses4}</div>
          <div>{language.termsAndConditionsOtherUses5}</div>
          <div>{language.termsAndConditionsOtherUses6}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsAvailability}</div>
        <div className="text">
          <div>{language.termsAndConditionsAvailability1}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsLinks}</div>
        <div className="text">
          <div>{language.termsAndConditionsLinks1}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsLimitation}</div>
        <div className="text">
          <div>{language.termsAndConditionsLimitation1}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsConfidentiality}</div>
        <div className="text">
          <div>{language.termsAndConditionsConfidentiality1}</div>
          <div>{language.termsAndConditionsConfidentiality2}</div>
          <div>{language.termsAndConditionsConfidentiality3}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsChanges}</div>
        <div className="text">
          <div>{language.termsAndConditionsChanges1}</div>
        </div>

        <div className="subtitle">{language.termsAndConditionsQuestions}</div>
        <div className="text">
          <div>{language.termsAndConditionsQuestions1}</div>
        </div>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default TermsAndConditionsMobile;
