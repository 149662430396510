import React from 'react';
import './About.mobile.scss';
import useLanguage from '../../languages/useLanguage';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import AboutCoachingMobile from './sections-mobile/AboutCoaching.mobile';
import AboutMeMobile from './sections-mobile/AboutMe.mobile';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';
import Page from '../../blocks/Page';

function AboutMobile() {
  const language = useLanguage();

  return (
    <Page className="about-page-mobile">
      <NavbarMobile />
      <div className="about-mobile-content">
        <ContentBoxMobile side="right" title={language.aboutCoaching}>
          <AboutCoachingMobile />
        </ContentBoxMobile>
        <ContentBoxMobile className="about-me" side="left" title={language.aboutMe}>
          <AboutMeMobile />
        </ContentBoxMobile>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default AboutMobile;
