import React from 'react';
import './Homepage.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import HomepageIntro from './sections/HomepageIntro';
import HomepageWhyCoaching from './sections/HomepageWhyCoaching';
import HomepageTestimonials from './sections/HomepageTestimonials';
import Page from '../../blocks/Page';

function Homepage() {
  return (
    <Page className="homepage">
      <Navbar />
      <div className="page-content">
        <HomepageIntro />
        <HomepageWhyCoaching />
        <HomepageTestimonials />
      </div>
      <Footer />
    </Page>
  );
}

export default Homepage;
