import React from 'react';
import './ComingSoon.mobile.scss';
import Hoverable from '../../blocks/Hoverable';
import useLanguage from '../../languages/useLanguage';
import useSetLanguage from '../../languages/useSetLanguage';

function ComingSoonMobile() {
  const language = useLanguage();
  const setLanguage = useSetLanguage();

  function handleEnglishClick() {
    setLanguage('English');
  }

  function handleFrenchClick() {
    setLanguage('French');
  }

  return (
    <div className="coming-soon-page-mobile">
      <div className="text-content">
        <div className="banner">
          <h1 className="title">{language.websiteTitle}</h1>
          <h2 className="subtitle paragraph-space">
            {language.comingSoonSubtitle}
            <Hoverable onClick={handleEnglishClick} space="before">
              English
            </Hoverable>
            -<Hoverable onClick={handleFrenchClick}>Français</Hoverable>
          </h2>
        </div>
        <div className="description">
          <h3 className="coming-soon">{language.comingSoonText}</h3>
          <p>{language.comingSoonBody1}</p>
          <p>{language.comingSoonBody2}</p>
          <p className="extra-paragraph-space">{language.comingSoonWorkWithMe}</p>
          <p>
            {language.comingSoonGetInTouch}
            <Hoverable space="before">hello@akimi.space</Hoverable>
            {language.comingSoonGetInTouch2}
          </p>
          <p>
            {language.comingSoonCallMe}
            <Hoverable space="before">+31 6 15083613</Hoverable>.
          </p>
        </div>
      </div>
      <div className="background-portrait" style={{ backgroundImage: 'url("/images/portrait-05.png")' }} />
    </div>
  );
}

export default ComingSoonMobile;
