import React from 'react';
import './PrivacyStatement.scss';
import useLanguage from '../../languages/useLanguage';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import Page from '../../blocks/Page';

function PrivacyStatement() {
  const language = useLanguage();

  return (
    <Page className="privacy-statement-page">
      <Navbar />
      <div className="privacy-page-content">
        <div className="main-title">{language.privacyPolicyTitle}</div>
        <div className="last-update">{language.privacyPolicyUpdate}</div>

        <div className="subtitle">{language.privacyPolicyPrivacy}</div>
        <div className="text">
          <div>{language.privacyPolicyPrivacy1}</div>
          <div>{language.privacyPolicyPrivacy2}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyData}</div>
        <div className="text">
          <div>{language.privacyPolicyData1}</div>
        </div>

        <div className="data-title">{language.privacyPolicyDataPersonal}</div>
        <div className="text">
          <div>{language.privacyPolicyDataPersonal1}</div>
          <div>{language.privacyPolicyDataPersonal2}</div>
          <div>{language.privacyPolicyDataPersonal3}</div>
        </div>

        <div className="data-title">{language.privacyPolicyDataUsage}</div>
        <div className="text">
          <div>{language.privacyPolicyDataUsage1}</div>
        </div>

        <div className="data-title">{language.privacyPolicyDataConsented}</div>
        <div className="text">
          <div>{language.privacyPolicyDataConsented1}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyLinks}</div>
        <div className="text">
          <div>{language.privacyPolicyLinks1}</div>
          <div>{language.privacyPolicyLinks2}</div>
        </div>

        <div className="subtitle">{language.privacyPolicySecurity}</div>
        <div className="text">
          <div>{language.privacyPolicySecurity1}</div>
          <div>{language.privacyPolicySecurity2}</div>
          <div>{language.privacyPolicySecurity3}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyChildren}</div>
        <div className="text">
          <div>{language.privacyPolicyChildren1}</div>
          <div>{language.privacyPolicyChildren2}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyCompliance}</div>
        <div className="text">
          <div>{language.privacyPolicyCompliance1}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyChanges}</div>
        <div className="text">
          <div>{language.privacyPolicyChanges1}</div>
        </div>

        <div className="subtitle">{language.privacyPolicyQuestions}</div>
        <div className="text">
          <div>{language.privacyPolicyQuestions1}</div>
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default PrivacyStatement;
