import React from 'react';
import './StepOne.scss';
import useLanguage from '../../../languages/useLanguage';
import PropTypes from 'prop-types';
import { WWM_ACTION_PLAN_AHEAD, WWM_ACTION_PLAN_FLOW, WWM_ACTION_PLAN_RESILIENCE } from '../../../constants';

function StepOne({ onNext }) {
  const language = useLanguage();

  return (
    <div className="step-one-content">
      <div className="step-one-title">{language.stepOneTitle}</div>
      <div className="choose-action-plan">
        <div className="choose-action-plan-intro">{language.stepOneIntro}</div>
        <div className="choose-action-plan-options">
          <div className="choose-resilience">
            <div className="choose-single-plan">
              <img
                alt="find resilience"
                className="resilience-image"
                onClick={() => onNext({ plan: WWM_ACTION_PLAN_RESILIENCE })}
                src="/images/plan-find-resilience.jpg"
              />
              <div className="choose-plan-name">{language.actionPlanResilience}</div>
            </div>
          </div>
          <div className="choose-head-ahead">
            <div className="choose-single-plan">
              <img
                alt="head ahead"
                className="head-ahead-image"
                onClick={() => onNext({ plan: WWM_ACTION_PLAN_AHEAD })}
                src="/images/plan-head-ahead.jpg"
              />
              <div className="choose-plan-name">{language.actionPlanHeadAhead}</div>
            </div>
          </div>
          <div className="choose-flow">
            <div className="choose-single-plan">
              <img
                alt="follow your flow"
                className="flow-image"
                onClick={() => onNext({ plan: WWM_ACTION_PLAN_FLOW })}
                src="/images/plan-follow-your-flow.jpg"
              />
              <div className="choose-plan-name">{language.actionPlanFlow}</div>
            </div>
          </div>
        </div>
        <div className="choose-action-plan-outro">
          <div>{language.stepOneOutro1}</div>
          <div>{language.stepOneOutro2}</div>
        </div>
      </div>
    </div>
  );
}

StepOne.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default StepOne;
