import React, { useRef } from 'react';
import './ButtonStart.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import scrollToElement from '../../../helpers/scrollToElement';

function ButtonStartMobile() {
  const ref = useRef(null);
  const language = useLanguage();
  const handleScrollDownClick = () => {
    scrollToElement(ref.current);
  };

  return (
    <div className="start-here-page-mobile">
      <div className="start-here-mobile-text">
        <div className="start-here-text-mobile-title">{language.sideBarTitle1}</div>
        <div className="start-here-text-mobile-subtitle">{language.sideBarTitle2}</div>
      </div>
      <i className="fad fa-chevron-down" onClick={handleScrollDownClick} ref={ref} />
    </div>
  );
}

export default ButtonStartMobile;
