import React, { useState } from 'react';
import TypewriterCycle from './TypewriterCycle';
import './Footer.scss';
import useLanguage from '../languages/useLanguage';
import usePost from '../hookers/usePost';
import router from '../router';

function Footer() {
  const language = useLanguage();
  const postContactForm = usePost('/api/contact/');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const typewriterItems = [language.typewriter1, language.typewriter2, language.typewriter3];
  const whatsappLink = `https://wa.me/31643251355?text=${encodeURIComponent(language.whatsappGreeting)}`;

  const handleSubmit = (event) => {
    const formData = new FormData(document.getElementById('contact-me-form'));
    const formJson = [...formData.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    event.preventDefault();
    console.log('Submitting footer', formJson);

    setLoading(true);
    postContactForm(formJson)
      .then(() => setSubmitted(true))
      .finally(() => setLoading(false));
  };

  return (
    <footer className="footer">
      <div className="left-side-footer">
        <div className="location">
          <div>{language.footerBased}</div>
          <div>{language.footerAvailable}</div>
        </div>
        <div>
          <div className="small-prints" onClick={() => router.navigate('termsAndConditions')}>
            {language.footerTerms}
          </div>
          <div className="small-prints" onClick={() => router.navigate('privacyStatement')}>
            {language.footerPrivacy}
          </div>
        </div>
      </div>
      <div className="middle-footer">
        <div>
          <TypewriterCycle items={typewriterItems} />
        </div>
        <form className="contact-me" id="contact-me-form" onSubmit={handleSubmit}>
          <div className="contact-name-email">
            <div className="contact-name">
              <input type="text" id="name" name="name" placeholder={language.footerContactName} />
            </div>
            <div className="contact-email">
              <input type="text" id="email" name="email" placeholder={language.footerContactEmail} />
            </div>
          </div>
          <div className="contact-text-area">
            <textarea id="subject" name="subject" placeholder={language.footerContactText} />
          </div>
          <div className="contact-send-button">
            {!submitted && <button disabled={loading}>{language.footerContactSend}</button>}
            {submitted && <div className="thank-you">{language.thankYou}</div>}
          </div>
        </form>
      </div>

      <div className="right-side-footer">
        <div className="social-media">
          <a href={whatsappLink} rel="noreferrer" target="_blank">
            <i className="fab fa-whatsapp" />
          </a>
          <a href="mailto:Akimi<hello@akimi.space>" rel="noreferrer" target="_blank">
            <i className="fal fa-envelope" />
          </a>
          <a href="https://www.instagram.com/akimi.space/" rel="noreferrer" target="_blank">
            <i className="fab fa-instagram" />
          </a>
          <a href="https://www.facebook.com/Akimi-103180982119522/" rel="noreferrer" target="_blank">
            <i className="fab fa-facebook-square" />
          </a>
          <a href="https://www.linkedin.com/in/akimiscarcella/" rel="noreferrer" target="_blank">
            <i className="fab fa-linkedin" />
          </a>
        </div>
        <div className="copyright">© akimi 2021</div>
      </div>
    </footer>
  );
}

export default Footer;
