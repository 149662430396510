import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import './ContentTransitioner.scss';

function ContentTransitioner({ children, step }) {
  return (
    <div className="content-transitioner">
      <SwitchTransition>
        <CSSTransition key={`step-${step}`} timeout={500}>
          {children}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

ContentTransitioner.propTypes = {
  children: PropTypes.node,
  step: PropTypes.number.isRequired,
};

export default ContentTransitioner;
