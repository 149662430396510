import React from 'react';
import './StepTwo.scss';
import ReactTooltip from 'react-tooltip';
import useLanguage from '../../../languages/useLanguage';
import PropTypes from 'prop-types';
import Tooltip from '../forms/Tooltip';
import { WWM_PACKAGE_BEYOND, WWM_PACKAGE_EXPRESS, WWM_PACKAGE_FULL, WWM_PACKAGE_LITE } from '../../../constants';

function StepTwo({ onNext }) {
  const language = useLanguage();

  const handleSubmit = (event) => {
    const formData = new FormData(document.querySelector('form'));
    const formJson = [...formData.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    formJson.package = event.nativeEvent.submitter.value;

    event.preventDefault();
    onNext(formJson);
  };

  return (
    <form className="step-two-content" onSubmit={handleSubmit}>
      <ReactTooltip />
      <div className="step-two-title">{language.stepTwoTitle}</div>
      <div>
        <div className="choose-package-intro">{language.stepTwoIntro}</div>
        <fieldset>
          <legend>{language.stepTwoMeetingOptions}</legend>
          <div className="meeting-frequency">
            <div>
              <input type="radio" id="weekly" name="meeting-frequency" required value="weekly" />
              <label htmlFor="weekly">{language.stepTwoMeetingFrequency1}</label>
            </div>
            <div>
              <input type="radio" id="biweekly" name="meeting-frequency" required value="biweekly" />
              <label htmlFor="biweekly">{language.stepTwoMeetingFrequency2}</label>
            </div>
          </div>
          <div className="meeting-location">
            <div>
              <input type="radio" id="online" name="meeting-location" required value="online" />
              <label htmlFor="online">{language.stepTwoMeetingLocation1}</label>
            </div>
            <div>
              <input type="radio" id="in-person" name="meeting-location" required value="online" />
              <label htmlFor="in-person">{language.stepTwoMeetingLocation2}</label>
            </div>
          </div>
        </fieldset>
        <div className="challenge-level-fieldset">
          <fieldset>
            <legend>{language.stepTwoChallengeLevel}</legend>
            <div className="challenge-level">
              <div>
                <input type="radio" id="little" name="challenge-level" required value="challenge" />
                <label htmlFor="little">{language.stepTwoChallengeLevel1}</label>
              </div>
              <div>
                <input type="radio" id="moderate" name="challenge-level" required value="moderate" />
                <label htmlFor="moderate">{language.stepTwoChallengeLevel2}</label>
              </div>
              <div>
                <input type="radio" id="lots" name="challenge-level" required value="lots" />
                <label htmlFor="lots">{language.stepTwoChallengeLevel3}</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="choose-package">
          <div className="choose-express-lite">
            <button className="choose-package-option choose-express" value={WWM_PACKAGE_EXPRESS}>
              <div className="ribbon">
                <span>{language.packageExpress}</span>
              </div>
              <div className="package-info">
                <div className="package-price-group circle-sketch-1">
                  <div className="price-package">{language.packageExpressTotal}</div>
                  <div className="price-session">
                    <div>{language.packageExpressSeparate}</div>
                    <Tooltip>
                      {language.tooltipPrices1} <br />
                      {language.tooltipPrices2} <br />
                      {language.tooltipPrices3} <br />
                      {language.tooltipPrices4} <br />
                    </Tooltip>
                  </div>
                </div>
                <div className="package-text">
                  <div className="package-element-largest">
                    <div>{language.packageTotal6sessions}</div>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionZero}</div>
                    <Tooltip>
                      {language.tooltipInsight1} <br />
                      {language.tooltipInsight2} <br />
                      {language.tooltipInsight3} <br />
                      {language.tooltipInsight4} <br />
                      {language.tooltipInsight5} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionsRegular}</div>
                    <Tooltip>{language.tooltipSessions}</Tooltip>
                  </div>
                </div>
              </div>
            </button>

            <button className="choose-package-option choose-lite" value={WWM_PACKAGE_LITE}>
              <div className="ribbon">
                <span>{language.packageLite}</span>
              </div>
              <div className="package-info">
                <div className="package-price-group circle-sketch-4">
                  <div className="price-package">{language.packageLiteTotal}</div>
                  <div className="price-session">
                    <div>{language.packageLiteSeparate}</div>
                    <Tooltip>
                      {language.tooltipPrices1} <br />
                      {language.tooltipPrices2} <br />
                      {language.tooltipPrices3} <br />
                      {language.tooltipPrices4} <br />
                    </Tooltip>
                  </div>
                </div>
                <div className="package-text">
                  <div className="package-element-largest">
                    <div>{language.packageTotal10sessions}</div>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionZero}</div>
                    <Tooltip>
                      {language.tooltipInsight1} <br />
                      {language.tooltipInsight2} <br />
                      {language.tooltipInsight3} <br />
                      {language.tooltipInsight4} <br />
                      {language.tooltipInsight5} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessions9Sessions}</div>
                    <Tooltip>{language.tooltipSessions}</Tooltip>
                  </div>
                </div>
              </div>
            </button>
          </div>

          <div className="choose-full-beyond">
            <button className="choose-package-option choose-full" value={WWM_PACKAGE_FULL}>
              <div className="ribbon">
                <span>{language.packageFull}</span>
              </div>
              <div className="package-info">
                <div className="package-price-group circle-sketch-3">
                  <div className="price-package">{language.packageFullTotal}</div>
                  <div className="price-session">
                    <div>{language.packageFullSeparate}</div>
                    <Tooltip>
                      {language.tooltipPrices1} <br />
                      {language.tooltipPrices2} <br />
                      {language.tooltipPrices3} <br />
                      {language.tooltipPrices4} <br />
                    </Tooltip>
                  </div>
                </div>
                <div className="package-text">
                  <div className="package-element-largest">
                    <div>{language.packageTotal6sessions}</div>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionZero}</div>
                    <Tooltip>
                      {language.tooltipInsight1} <br />
                      {language.tooltipInsight2} <br />
                      {language.tooltipInsight3} <br />
                      {language.tooltipInsight4} <br />
                      {language.tooltipInsight5} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessions4Sessions}</div>
                    <Tooltip>{language.tooltipSessions}</Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionClosing}</div>
                    <Tooltip>
                      {language.tooltipSessionClosing1} <br />
                      {language.tooltipSessionClosing2} <br />
                      {language.tooltipSessionClosing3} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-large">
                    <div>{language.packageTouchdown}</div>
                    <Tooltip>
                      {language.tooltipTouchdowns1} <br />
                      {language.tooltipTouchdowns2} <br />
                      {language.tooltipTouchdowns3} <br />
                      {language.tooltipTouchdowns4} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-large">
                    <div>{language.packageReports}</div>
                    <Tooltip>
                      {language.tooltipReports1} <br />
                      {language.tooltipReports2} <br />
                      {language.tooltipReports3} <br />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </button>

            <button className="choose-package-option choose-beyond" value={WWM_PACKAGE_BEYOND}>
              <div className="ribbon">
                <span>{language.packageBeyond}</span>
              </div>
              <div className="package-info">
                <div className="package-price-group circle-sketch-2">
                  <div className="price-package">{language.packageBeyondTotal}</div>
                  <div className="price-session">
                    <div>{language.packageBeyondSeparate}</div>
                    <Tooltip>
                      {language.tooltipPrices1} <br />
                      {language.tooltipPrices2} <br />
                      {language.tooltipPrices3} <br />
                      {language.tooltipPrices4} <br />
                    </Tooltip>
                  </div>
                </div>
                <div className="package-text">
                  <div className="package-element-largest">
                    <div>{language.packageTotal10sessions}</div>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionZero}</div>
                    <Tooltip>
                      {language.tooltipInsight1} <br />
                      {language.tooltipInsight2} <br />
                      {language.tooltipInsight3} <br />
                      {language.tooltipInsight4} <br />
                      {language.tooltipInsight5} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionsRegularPlus}</div>
                    <Tooltip>{language.tooltipSessions}</Tooltip>
                  </div>
                  <div className="package-element-small">
                    <div>{language.packageSessionClosing}</div>
                    <Tooltip>
                      {language.tooltipSessionClosing1} <br />
                      {language.tooltipSessionClosing2} <br />
                      {language.tooltipSessionClosing3} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-large">
                    <div>{language.packageTouchdown}</div>
                    <Tooltip>
                      {language.tooltipTouchdowns1} <br />
                      {language.tooltipTouchdowns2} <br />
                      {language.tooltipTouchdowns3} <br />
                      {language.tooltipTouchdowns4} <br />
                    </Tooltip>
                  </div>
                  <div className="package-element-large">
                    <div>{language.packageReports}</div>
                    <Tooltip>
                      {language.tooltipReports1} <br />
                      {language.tooltipReports2} <br />
                      {language.tooltipReports3} <br />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

StepTwo.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default StepTwo;
