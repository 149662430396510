import React from 'react';
import './BlogCardLandscape.scss';
import PropTypes from 'prop-types';
import getPathForRoute from '../helpers/getPathForRoute';
import { ROUTE_BLOG } from '../router';

function BlogCardLandscape({ date, description, image, length, path, side, style, title, topic }) {
  return (
    <a className={`blog-card-landscape ${side}`} href={`${getPathForRoute(ROUTE_BLOG)}/${path}`} style={style}>
      <div className="card-background" style={{ backgroundImage: `url(${image})` }} />
      <div className="card-length">{length}</div>
      <div className="card-topic">
        <span className="circle-sketch">{topic}</span>
      </div>
      <div className="card-date">{date}</div>
      <div className="card-box">
        <div className="mobile-shading" />
        <div className="card-title">{title}</div>
        <div className="card-description">{description}</div>
      </div>
    </a>
  );
}

BlogCardLandscape.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  side: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
};

export default BlogCardLandscape;
