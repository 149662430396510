import React, { useEffect, useState } from 'react';
import './ButtonEnd.scss';
import useLanguage from '../../../languages/useLanguage';
import { useRouter } from 'react-router5';
import classNames from 'classnames';
import { ROUTE_HOMEPAGE } from '../../../router';

function ButtonEnd() {
  const language = useLanguage();
  const router = useRouter();
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimating(true), 500);
    setTimeout(() => router.navigate(ROUTE_HOMEPAGE), 4_500);
  }, [router]);

  return (
    <div className="end-here-button">
      <div className="thank-you">{language.thankYou}</div>
      <div className="get-in-touch">{language.getInTouch}</div>
      <div className={classNames('progress-bar', { animating })} />
    </div>
  );
}

export default ButtonEnd;
