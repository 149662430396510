import React, { useState } from 'react';
import LanguageContext from './LanguageContext';
import PropTypes from 'prop-types';
import englishText from './englishText.json';
import frenchText from './frenchText.json';
import englishBlogText from './englishBlogText.json';
import frenchBlogText from './frenchBlogText.json';

function LanguageProvider(props) {
  const [activeLanguage, setActiveLanguage] = useState('English');
  const [blogText, setBlogText] = useState(englishBlogText);
  const [text, setText] = useState(englishText);

  function setLanguage(language) {
    if (language === 'English') {
      setActiveLanguage('English');
      setBlogText(englishBlogText);
      setText(englishText);
    }

    if (language === 'French') {
      setActiveLanguage('French');
      setBlogText(frenchBlogText);
      setText(frenchText);
    }
  }

  const context = { activeLanguage, blogText, setLanguage, text };

  return <LanguageContext.Provider value={context}>{props.children}</LanguageContext.Provider>;
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
