import { SCROLL_CONTENT_ELEMENT_ID } from '../constants';

const scrollToElement = (element) => {
  const scrollElement = document.getElementById(SCROLL_CONTENT_ELEMENT_ID);
  const scrollTop = scrollElement?.scrollTop ?? 0;
  const top = element?.getBoundingClientRect().top ?? 0;
  scrollElement?.scrollTo({ behavior: 'smooth', top: top + scrollTop });
};

export default scrollToElement;
