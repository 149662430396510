import React, { useState } from 'react';
import './StepOne.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import classNames from 'classnames';
import { WWM_ACTION_PLAN_AHEAD, WWM_ACTION_PLAN_FLOW, WWM_ACTION_PLAN_RESILIENCE } from '../../../constants';
import HiddenRadioInput from '../forms/HiddenRadioInput';

function StepOneMobile() {
  const language = useLanguage();
  const [selectedActionPlan, setSelectedActionPlan] = useState(undefined);

  const selectionMade = selectedActionPlan !== undefined;
  const isResilienceSelected = selectedActionPlan === WWM_ACTION_PLAN_RESILIENCE;
  const isPlanAheadSelected = selectedActionPlan === WWM_ACTION_PLAN_AHEAD;
  const isFlowSelected = selectedActionPlan === WWM_ACTION_PLAN_FLOW;

  const handleSelection = (actionPlan) => {
    setSelectedActionPlan(actionPlan);
  };

  return (
    <div className="step-one-content-mobile">
      <div className="step-one-title-mobile">{language.stepOneTitle}</div>
      <div className="choose-action-plan-mobile">
        <div className="choose-action-plan-intro-mobile">{language.stepOneIntro}</div>
        <div className="choose-action-plan-options-mobile">
          <div
            className={classNames('plan-option-section-mobile', {
              selected: isResilienceSelected,
              ['selection-made']: selectionMade,
            })}
          >
            <div className="choose-text-mobile">{language.actionPlanResilience}</div>
            <HiddenRadioInput
              id={`package-${WWM_ACTION_PLAN_RESILIENCE}`}
              name="plan"
              value={WWM_ACTION_PLAN_RESILIENCE}
            />
            <label
              htmlFor={`package-${WWM_ACTION_PLAN_RESILIENCE}`}
              onClick={() => handleSelection(WWM_ACTION_PLAN_RESILIENCE)}
            >
              <img alt="find resilience" src="/images/plan-find-resilience.jpg" />
            </label>
          </div>
          <div
            className={classNames('plan-option-section-mobile', {
              selected: isPlanAheadSelected,
              ['selection-made']: selectionMade,
            })}
          >
            <div className="choose-text-mobile">{language.actionPlanHeadAhead}</div>
            <HiddenRadioInput id={`package-${WWM_ACTION_PLAN_AHEAD}`} name="plan" value={WWM_ACTION_PLAN_AHEAD} />
            <label htmlFor={`package-${WWM_ACTION_PLAN_AHEAD}`} onClick={() => handleSelection(WWM_ACTION_PLAN_AHEAD)}>
              <img alt="head ahead" src="/images/plan-head-ahead.jpg" />
            </label>
          </div>
          <div
            className={classNames('plan-option-section-mobile', {
              selected: isFlowSelected,
              ['selection-made']: selectionMade,
            })}
          >
            <div className="choose-text-mobile">{language.actionPlanFlow}</div>
            <HiddenRadioInput id={`package-${WWM_ACTION_PLAN_AHEAD}`} name="plan" value={WWM_ACTION_PLAN_AHEAD} />
            <label htmlFor={`package-${WWM_ACTION_PLAN_FLOW}`} onClick={() => handleSelection(WWM_ACTION_PLAN_FLOW)}>
              <img alt="follow your flow" src="/images/plan-follow-your-flow.jpg" />
            </label>
          </div>
        </div>
        <div className="choose-action-plan-outro-mobile">
          <div>{language.stepOneOutro1}</div>
          <br />
          <div>{language.stepOneOutro2}</div>
        </div>
      </div>
    </div>
  );
}

export default StepOneMobile;
