import React, { useRef } from 'react';
import './HomepageIntro.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import scrollToElement from '../../../helpers/scrollToElement';

function HomepageIntroMobile() {
  const ref = useRef(null);
  const language = useLanguage();

  const handleScrollDownClick = () => {
    scrollToElement(ref.current);
  };

  return (
    <div className="introduction-mobile">
      <div className="image-wrapper">
        <img alt="portrait akimi" className="intro-image" src="/images/portrait.png" />
        <div className="over-image-text">
          <div className="intro-title">
            <div>{language.homeIntroTitle}</div>
          </div>
          <div className="intro-description">
            <div>{language.homeIntroText1}</div>
            <br />
            <div>{language.homeIntroText2}</div>
            <br />
            <div className="intro-description-bold">
              <div>{language.homeIntroText3}</div>
              <div className="intro-description-bold-text">{language.homeIntroTextBoldHover}</div>
              <div>{language.homeIntroText4}</div>
            </div>
          </div>
        </div>
      </div>
      <i className="fad fa-chevron-down" onClick={handleScrollDownClick} ref={ref} />
    </div>
  );
}

export default HomepageIntroMobile;
