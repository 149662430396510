import PropTypes from 'prop-types';
import React from 'react';
import './SideBar.scss';
import useLanguage from '../../../languages/useLanguage';
import ContentTransitioner from '../wizard/ContentTransitioner';
import classNames from 'classnames';

function formatStep(step) {
  return step >= 1 && step <= 3 ? step : ' ';
}

function SideBar({ step }) {
  const language = useLanguage();

  return (
    <div className="side-bar-content">
      <div className="side-bar-background-number">
        <ContentTransitioner step={step}>
          <div className="step-counter">
            {formatStep(step)}
            {step === 4 && <i className="fal fa-check" />}
          </div>
        </ContentTransitioner>
      </div>
      <div className="side-bar-title">
        <div>{language.sideBarTitle1}</div>
      </div>
      <div className="side-bar-subtitle">
        <div>{language.sideBarTitle2}</div>
      </div>
      <div className="side-bar-steps">
        <div className={classNames({ active: step === 1 })}>{language.sideBar1}</div>
        <i className="fal fa-ellipsis-v" />
        <div className={classNames({ active: step === 2 })}>{language.sideBar2}</div>
        <i className="fal fa-ellipsis-v" />
        <div className={classNames({ active: step === 3 })}>{language.sideBar3}</div>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default SideBar;
