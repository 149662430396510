import { useEffect, useState } from 'react';
import { SCROLL_CONTENT_ELEMENT_ID } from '../constants';

function useWizardResize(fullscreen, ref) {
  const [isAnimating, setAnimating] = useState(false);
  const [animationValues, setAnimationValues] = useState({ maxHeight: '60vh', position: 'relative', top: 0 });

  /**
   * Watch for transitions ending and set any animation flags to false in this event
   */
  useEffect(() => {
    const observer = new ResizeObserver(function () {
      const scrollElement = document.getElementById(SCROLL_CONTENT_ELEMENT_ID);
      scrollElement.scrollTop = animationValues.top;
    });

    function handleTransitionEnd(event) {
      if (event.target === ref.current) {
        setAnimating(false);
      }
    }

    const element = ref.current;
    observer.observe(element);
    element?.addEventListener('transitionend', handleTransitionEnd);
    return function () {
      if (element !== null) observer.unobserve(element);
      element?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [animationValues, ref]);

  /**
   * Invoke a render to apply animation class name
   */
  useEffect(() => {
    setAnimating(fullscreen);
  }, [fullscreen, ref]);

  /**
   * Update the animation values for our fullscreen view
   */
  useEffect(() => {
    if (isAnimating) {
      const top = ref.current?.getBoundingClientRect().top ?? 0;
      setAnimationValues({ maxHeight: '100vh', position: 'absolute', top: top });
    }
  }, [isAnimating, ref]);

  const className = isAnimating ? 'animating' : '';

  const { maxHeight, position } = animationValues;
  const containerStyle = { maxHeight };
  const contentStyle = { display: 'flex', maxHeight, position };

  return { className, containerStyle, contentStyle, isAnimating };
}

export default useWizardResize;
