import React, { useEffect, useState } from 'react';
import './WorkWithMe.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import useLanguage from '../../languages/useLanguage';
import ContentBox from '../../blocks/ContentBox';
import SideBar from './sections/SideBar';
import StepOne from './sections/StepOne';
import StepTwo from './sections/StepTwo';
import StepThree from './sections/StepThree';
import ButtonStart from './sections/ButtonStart';
import ButtonEnd from './sections/ButtonEnd';
import Wizard from './wizard/Wizard';
import Page from '../../blocks/Page';
import usePost from '../../hookers/usePost';

function getStepNode(step, nextStep) {
  switch (step) {
    case 0:
      return <ButtonStart onStart={nextStep} />;

    case 1:
      return <StepOne onNext={nextStep} />;

    case 2:
      return <StepTwo onNext={nextStep} />;

    case 3:
      return <StepThree onNext={nextStep} />;

    case 4:
      return <ButtonEnd />;

    default:
      return <ButtonStart onStart={nextStep} />;
  }
}

function WorkWithMe() {
  const language = useLanguage();
  const postWorkWithMeForm = usePost('/api/work-with-me/');
  const [formJson, setFormJson] = useState({});

  // The step can have the value 0 = "start", 1 = "choose", 2 = "tweak", 3 = "unlock" or 4 = "finish"
  const [step, setStep] = useState(0);

  function nextStep(formValues = {}) {
    setStep((current) => current + 1);
    setFormJson((current) => ({ ...current, ...formValues }));
  }

  useEffect(() => {
    if (step === 4) {
      console.log('Submitting work with me', formJson);
      postWorkWithMeForm(formJson);
    }
  }, [formJson, postWorkWithMeForm, step]);

  return (
    <Page className="work-with-me-page">
      <Navbar />
      <div className="work-with-me-content">
        <ContentBox side="right" title={language.workWithMeTitle}>
          <SideBar step={step} />
          <Wizard className="work-with-me-wizard" step={step}>
            {getStepNode(step, nextStep)}
          </Wizard>
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

export default WorkWithMe;
