import { useMemo } from 'react';

const usePost = (url) => {
  return useMemo(
    () => (contactForm) =>
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(contactForm),
      }).catch((error) => console.log('Failed to submit contact form', error)),
    [url],
  );
};

export default usePost;
