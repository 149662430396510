import React from 'react';
import './Blog.mobile.scss';
import './BlogPost.mobile.scss';
import Page from '../../blocks/Page';
import PropTypes from 'prop-types';
import getBlogPost from './getBlogPost';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';

function BlogPostMobile({ path }) {
  return (
    <Page className="blog-post-page-mobile">
      <NavbarMobile />
      <div className="blog-page-content">{getBlogPost(path, true)}</div>
      <FooterMobile />
    </Page>
  );
}

BlogPostMobile.propTypes = {
  path: PropTypes.string,
};

export default BlogPostMobile;
