import React from 'react';
import './ButtonStart.scss';
import useLanguage from '../../../languages/useLanguage';
import PropTypes from 'prop-types';

function ButtonStart({ onStart }) {
  const language = useLanguage();

  return (
    <div className="button-start" onClick={() => onStart()}>
      <a className="start-here-button">{language.startHere}</a>
    </div>
  );
}

ButtonStart.propTypes = {
  onStart: PropTypes.func.isRequired,
};

export default ButtonStart;
