import React from 'react';
import './AboutMe.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import BorderedImage from '../../../blocks/BorderedImage';

function AboutMeMobile() {
  const language = useLanguage();

  return (
    <div className="about-me-mobile">
      <div className="about-me-content-mobile">
        <div className="introduction-mobile">
          <div className="title">{language.aboutMeBody1}</div>
          <div className="subtitle">{language.aboutMeBody2}</div>
          <div className="bold-text">{language.aboutMeBody3}</div>
        </div>
        <div className="about-me-image-mobile">
          <BorderedImage borderColor="#d4b466" image="/images/portrait-09.png" />
        </div>
      </div>
      <div className="about-me-main-text-mobile">
        <div>{language.aboutMeBody4}</div>
        <br />
        <div>{language.aboutMeBody5}</div>
        <br />
        <div>{language.aboutMeBody6}</div>
        <br />
        <div>{language.aboutMeBody7}</div>
        <br />
        <div>{language.aboutMeBody8}</div>
        <br />
        <div>{language.aboutMeBody9}</div>
        <br />
        <div>{language.aboutMeBody10}</div>
      </div>
    </div>
  );
}

export default AboutMeMobile;
