import React from 'react';
import PropTypes from 'prop-types';
import './NavbarItems.scss';

function NavbarItems(props) {
  const style = {};

  return (
    <span className="navbar-item" onClick={props.onClick} style={style}>
      {props.children}
    </span>
  );
}

NavbarItems.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default NavbarItems;
