import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './BorderedImage.scss';

function BorderedImage({ borderColor, className, image }) {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(function () {
    window.addEventListener('resize', function () {
      if (ref.current instanceof HTMLImageElement) {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
      }
    });
  }, []);

  function handleLoad(event) {
    setHeight(event.target.clientHeight);
    setWidth(event.target.clientWidth);
  }

  return (
    <div className={`bordered-image ${className}`}>
      <img onLoad={handleLoad} ref={ref} src={image} />
      <div className="border" style={{ borderColor, height, width }} />
    </div>
  );
}

BorderedImage.propTypes = {
  borderColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default BorderedImage;
