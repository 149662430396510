import React, { useEffect, useState } from 'react';
import './HomepageTestimonials.scss';
import useLanguage from '../../../languages/useLanguage';
import TestimonialPreview from './TestimonialPreview';
import PageIndicator from '../../../blocks/PageIndicator';
import FlatButton from '../../../blocks/FlatButton';

function HomepageTestimonials() {
  const language = useLanguage();
  const [requestedTestimonialIndex, setRequestedTestimonialIndex] = useState(undefined);
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const testimonials = [
    {
      name: 'Jean-Baptiste',
      text: language.homeTestimonialPreviewJB,
      image: '/images/jeanbaptiste.jpg',
      location: language.countryFrance,
    },
    {
      name: 'Sarah',
      text: language.homeTestimonialPreviewSarah,
      image: '/images/sarah.jpg',
      location: language.countryNetherlands,
    },
    {
      name: 'Sia',
      text: language.homeTestimonialPreviewSia,
      image: '/images/sia.jpg',
      location: language.countryUK,
    },
    {
      name: 'Katherina',
      text: language.homeTestimonialPreviewKatherina,
      image: '/images/katherina.jpg',
      location: language.countryNetherlands,
    },
    {
      name: 'Christina',
      text: language.homeTestimonialPreviewChristina,
      image: '/images/christina.jpg',
      location: language.countryUK,
    },
    {
      name: 'Franzi',
      text: language.homeTestimonialPreviewFranzi,
      image: '/images/franzi.jpg',
      location: language.countryGermany,
    },
    {
      name: 'Simon',
      text: language.homeTestimonialPreviewSimon,
      image: '/images/simon.jpg',
      location: language.countryNetherlands,
    },
  ];

  const leftIndex = testimonialIndex === 0 ? testimonials.length - 1 : testimonialIndex - 1;
  const rightIndex = testimonialIndex === testimonials.length - 1 ? 0 : testimonialIndex + 1;

  const testimonialsLength = testimonials.length;

  useEffect(
    function () {
      const autoCycleTimeout = setTimeout(function () {
        let newTestimonialIndex = testimonialIndex + 1;
        if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
        if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;
        setTestimonialIndex(newTestimonialIndex);
      }, 10_000);

      return function () {
        clearTimeout(autoCycleTimeout);
      };
    },
    [testimonialsLength, testimonialIndex],
  );

  useEffect(
    function () {
      if (requestedTestimonialIndex === undefined) return;

      if (requestedTestimonialIndex === testimonialIndex) {
        setRequestedTestimonialIndex(undefined);
        return;
      }

      const itemsBetween = Math.abs(testimonialIndex - requestedTestimonialIndex);
      const halfItemsCount = Math.floor(testimonialsLength / 2);
      const isGoingRound = itemsBetween > halfItemsCount;

      if (requestedTestimonialIndex < testimonialIndex) {
        const prevTimeout = setTimeout(function () {
          const offsetAmount = isGoingRound ? 1 : -1;
          let newTestimonialIndex = testimonialIndex + offsetAmount;
          if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
          if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;

          setTestimonialIndex(newTestimonialIndex);
        }, 150);
        return function () {
          clearTimeout(prevTimeout);
        };
      }

      const nextTimeout = setTimeout(function () {
        const offsetAmount = isGoingRound ? -1 : 1;
        let newTestimonialIndex = testimonialIndex + offsetAmount;
        if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
        if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;

        setTestimonialIndex(newTestimonialIndex);
      }, 150);
      return function () {
        clearTimeout(nextTimeout);
      };
    },
    [requestedTestimonialIndex, testimonialIndex, testimonialsLength],
  );

  const handlePreviousClick = () => {
    setTestimonialIndex(leftIndex);
  };

  const handleNextClick = () => {
    setTestimonialIndex(rightIndex);
  };

  const handleIndicatorClick = (newTestimonialIndex) => {
    setRequestedTestimonialIndex(newTestimonialIndex);
  };

  return (
    <div className="home-testimonials">
      <div className="testimonials-preview-title">{language.homeTestimonialsTitle}</div>

      <div className="testimonials-preview-carousel">
        <i className="fad fa-chevron-left" onClick={handlePreviousClick} />
        {testimonials.map(function (testimonial, pageIndex) {
          return (
            <TestimonialPreview
              key={pageIndex}
              {...testimonial}
              activePageIndex={testimonialIndex}
              pageIndex={pageIndex}
              maxPageIndex={testimonials.length - 1}
            />
          );
        })}
        <i className="fad fa-chevron-right" onClick={handleNextClick} />
      </div>

      <div className="page-indicators">
        {testimonials.map(function (_, pageIndex) {
          return (
            <PageIndicator
              key={pageIndex}
              activePageIndex={testimonialIndex}
              onClick={handleIndicatorClick}
              pageIndex={pageIndex}
            />
          );
        })}
      </div>
      <FlatButton label={language.readMoreButton} route="testimonials" />
    </div>
  );
}

export default HomepageTestimonials;
