import React from 'react';
import useBlogLanguage from '../../../languages/useBlogLanguage';
import BorderedImage from '../../../blocks/BorderedImage';
import SocialMedia from '../SocialMedia';

function _003_Post() {
  const blogLanguage = useBlogLanguage();

  return (
    <div className="blog-post-page">
      <div className="blog-post-banner">
        <img
          alt="Get out of your comfort zone regularly"
          className="blog-post-photo-banner"
          src="/images/blog-post-003-04.jpg"
        />
        <div className="blog-post-title">
          <div>{blogLanguage._003_Title1}</div>
          <div>{blogLanguage._003_Title2}</div>
        </div>
        <div className="banner-bottom-line">
          <div className="post-details">
            <div className="blog-post-date">{blogLanguage._003_Date}</div>
            <div> | </div>
            <div className="blog-topic">{blogLanguage.topicSelfLeadership}</div>
            <div> | </div>
            <div className="blog-reading-time">11 mins</div>
          </div>
          <SocialMedia />
        </div>
      </div>

      <div className="blog-post-content">
        <div className="blog-post-introduction">
          <div>{blogLanguage._003_Introduction1}</div>
          <div>{blogLanguage._003_Introduction2}</div>
          <div>{blogLanguage._003_Introduction3}</div>
          <div>{blogLanguage._003_Introduction4}</div>
        </div>
        <div className="blog-post-paragraphs">
          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_HireSomeoneTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_HireSomeone1}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_HireSomeone2}</span>
              <span>{blogLanguage._003_HireSomeone3}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone4}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_HireSomeone5}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_HireSomeone6}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone7}</span>
              <span className="highlighted-sketch">{blogLanguage._003_HireSomeone8}</span>
              <span>{blogLanguage._003_HireSomeone9}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_HireSomeone10}</span>
              <span>{blogLanguage._003_HireSomeone11}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone12}</span>
              <span className="highlighted-sketch">{blogLanguage._003_HireSomeone13}</span>
              <span>{blogLanguage._003_HireSomeone14}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone15}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._003_HireSomeone16}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone17}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._003_HireSomeone18}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone19}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._003_HireSomeone20}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone21}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone22}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone23}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_HireSomeone24}</span>
              <span>{blogLanguage._003_HireSomeone25}</span>
            </div>
            <div>
              <span>{blogLanguage._003_HireSomeone26}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_WhereToStartTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_WhereToStart1}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart2}</span>
              <span className="highlighted-sketch">{blogLanguage._003_WhereToStart3}</span>
              <span>{blogLanguage._003_WhereToStart4}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_WhereToStart5}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_WhereToStart6}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_WhereToStart7}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart8}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart9}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart10}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_WhereToStart11}</span>
              <span>{blogLanguage._003_WhereToStart12}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart13}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart14}</span>
              <span className="highlighted-sketch">{blogLanguage._003_WhereToStart15}</span>
              <span>{blogLanguage._003_WhereToStart16}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart17}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart18}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart19}</span>
            </div>
            <div className="blog-photo-paragraph">
              <BorderedImage
                alt="Did I know where to start?"
                borderColor="#d4dcc4"
                className="image-bordered"
                image="/images/blog-post-003-05.png"
              />
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart20}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart21}</span>
            </div>
            <div>
              <span>{blogLanguage._003_WhereToStart22}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_WhereToStart23}</span>
              <span>{blogLanguage._003_WhereToStart24}</span>
              <span className="highlighted-sketch">{blogLanguage._003_WhereToStart25}</span>
              <span>{blogLanguage._003_WhereToStart26}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_EasinessTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_Easiness1}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_Easiness2}</span>
              <span>{blogLanguage._003_Easiness3}</span>
            </div>
            <div className="blog-photo-paragraph">
              <img alt="Dunning-Kruger curve" className="image-fade-in" src="/images/blog-post-003-DK1.png" />
            </div>
            <div>
              <span>{blogLanguage._003_Easiness4}</span>
              <span className="highlighted-sketch">{blogLanguage._003_Easiness5}</span>
              <span>{blogLanguage._003_Easiness6}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness7}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_Easiness8}</span>
              <span>{blogLanguage._003_Easiness9}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness10}</span>
              <span className="highlighted-sketch">{blogLanguage._003_Easiness11}</span>
              <span>{blogLanguage._003_Easiness12}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness13}</span>
            </div>
            <div className="blog-photo-paragraph">
              <img alt="Dunning-Kruger curve" className="image-fade-in" src="/images/blog-post-003-DK2.png" />
            </div>
            <div>
              <span>{blogLanguage._003_Easiness14}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._003_Easiness15}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness16}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness17}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness18}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_Easiness19}</span>
              <span>{blogLanguage._003_Easiness20}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness21}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness22}</span>
              <span className="highlighted-sketch">{blogLanguage._003_Easiness23}</span>
              <span>{blogLanguage._003_Easiness24}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness25}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness26}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness27}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness28}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_Easiness29}</span>
              <span>{blogLanguage._003_Easiness30}</span>
            </div>
            <div>
              <span>{blogLanguage._003_Easiness31}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_CareerMakingTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_CareerMaking1}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking2}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking3}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_CareerMaking4}</span>
              <span>{blogLanguage._003_CareerMaking5}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking6}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking7}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_CareerMaking8}</span>
              <span>{blogLanguage._003_CareerMaking9}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking10}</span>
              <span className="highlighted-sketch">{blogLanguage._003_CareerMaking11}</span>
              <span>{blogLanguage._003_CareerMaking12}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking13}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking14}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_CareerMaking15}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_CareerMaking16}</span>
            </div>
            <div>
              <span>{blogLanguage._003_CareerMaking17}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_CareerMaking18}</span>
              <span>{blogLanguage._003_CareerMaking19}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_OnMyOwnTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_OnMyOwn1}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn2}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_OnMyOwn3}</span>
              <span>{blogLanguage._003_OnMyOwn4}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn5}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn6}</span>
              <span className="highlighted-sketch">{blogLanguage._003_OnMyOwn7}</span>
              <span>{blogLanguage._003_OnMyOwn8}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn9}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn10}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_OnMyOwn11}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._003_OnMyOwn12}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn13}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_OnMyOwn14}</span>
              <span>{blogLanguage._003_OnMyOwn15}</span>
              <span className="highlighted-sketch">{blogLanguage._003_OnMyOwn16}</span>
              <span>{blogLanguage._003_OnMyOwn17}</span>
            </div>
            <div>
              <span>{blogLanguage._003_OnMyOwn18}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-question" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._003_LessonsLearntTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt1}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt2}</span>
              <span className="highlighted-sketch">{blogLanguage._003_LessonsLearnt3}</span>
              <span>{blogLanguage._003_LessonsLearnt4}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt5}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_LessonsLearnt6}</span>
              <span>{blogLanguage._003_LessonsLearnt7}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt8}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt9}</span>
              <span className="highlighted-sketch">{blogLanguage._003_LessonsLearnt10}</span>
              <span>{blogLanguage._003_LessonsLearnt11}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._003_LessonsLearnt12}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt13}</span>
            </div>
            <div>
              <span>{blogLanguage._003_LessonsLearnt14}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._003_LessonsLearnt15}</span>
              <span>{blogLanguage._003_LessonsLearnt16}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default _003_Post;
