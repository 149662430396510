import React from 'react';
import PropTypes from 'prop-types';
import './PageIndicator.scss';
import useIsMobile from '../hookers/useIsMobile';

function PageIndicator({ pageIndex, activePageIndex, onClick }) {
  const isMobile = useIsMobile();

  let className = 'page-indicator';
  if (activePageIndex === pageIndex) className = 'page-indicator active';
  if (!isMobile) className += ' desktop';

  function handleClick() {
    onClick(pageIndex);
  }

  return <div className={className} onClick={handleClick} />;
}

PageIndicator.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  activePageIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PageIndicator;
