import React, { useEffect, useState } from 'react';
import './ButtonEnd.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import { useRouter } from 'react-router5';
import classNames from 'classnames';
import { ROUTE_HOMEPAGE } from '../../../router';

function ButtonEndMobile() {
  const language = useLanguage();
  const router = useRouter();
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setAnimating(true);
    setTimeout(() => router.navigate(ROUTE_HOMEPAGE), 4_000);
  }, [router]);

  return (
    <div className={classNames('end-here-button-mobile', { animating })}>
      <div className="thank-you-mobile">{language.thankYou}</div>
      <div className="get-in-touch-mobile">{language.getInTouch}</div>
      <div className="progress-bar" />
    </div>
  );
}

export default ButtonEndMobile;
