import React from 'react';
import PropTypes from 'prop-types';

function TextInput({ className, id, placeholder, required, type = 'text' }) {
  return <input className={className} id={id} name={id} placeholder={placeholder} required={required} type={type} />;
}

TextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
};

export default TextInput;
