import React from 'react';
import './TestimonialCard.scss';
import PropTypes from 'prop-types';

function TestimonialCard({ children, color, name, image, location }) {
  return (
    <div className="testimonial-card">
      <div className="front-card">
        <div className="background" style={{ backgroundImage: `url(${image})` }} />
        <div className="front-text">
          <div className="name">{name}</div>
          <div className="location">{location}</div>
        </div>
      </div>
      <div className="back-card" style={{ backgroundColor: color }}>
        {children}
      </div>
    </div>
  );
}

TestimonialCard.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default TestimonialCard;
