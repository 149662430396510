import React from 'react';
import _001_Post from './posts/_001_Post';
import _002_Post from './posts/_002_Post';
import _003_Post from './posts/_003_Post';
import _004_Post from './posts/_004_Post';
import _004_PostMobile from './posts/_004_Post.mobile';

export const _001_POST = 'how-to-find-a-coach-that-is-right-for-you';
export const _002_POST = '7-lessons-from-the-hospitality-industry';
export const _003_POST = 'get-out-of-your-comfort-zone-regularly';
export const _004_POST = 'new-year-new-me-new-who';

const getBlogPost = (path, isMobile = false) => {
  switch (path) {
    case _001_POST:
      return <_001_Post />;

    case _002_POST:
      return <_002_Post />;

    case _003_POST:
      return <_003_Post />;

    case _004_POST:
      return isMobile ? <_004_PostMobile /> : <_004_Post />;

    default:
      return 'Could not find the blog post you were looking for...';
  }
};

export default getBlogPost;
