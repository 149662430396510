import React, { useEffect, useState } from 'react';
import './Blog.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import useLanguage from '../../languages/useLanguage';
import ContentBox from '../../blocks/ContentBox';
import Page from '../../blocks/Page';
import getBlogPosts from './getBlogPosts';
import PostTiles from './PostTiles';
import useBlogLanguage from '../../languages/useBlogLanguage';

const POSTS_PER_CHUNK = 20;

const chunkPosts = (posts) => {
  let currentChunk = [];
  const result = [currentChunk];

  for (const post of posts) {
    currentChunk.push(post);

    if (currentChunk.length === POSTS_PER_CHUNK) {
      currentChunk = [];
    }

    if (currentChunk.length === 1 && currentChunk !== result[result.length - 1]) {
      result.push(currentChunk);
    }
  }

  return result;
};

function Blog() {
  const language = useLanguage();
  const blogLanguage = useBlogLanguage();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    setBlogPosts(getBlogPosts(blogLanguage));
  }, [blogLanguage]);

  return (
    <Page className="blog-page">
      <Navbar />
      <div className="blog-page-content">
        <ContentBox side="right" title={language.blogTitle}>
          {chunkPosts(blogPosts).map((chunk, index) => (
            <PostTiles key={index} posts={chunk} />
          ))}
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

export default Blog;
