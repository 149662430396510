import React from 'react';
import './HomepageWhyCoaching.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import BorderedImage from '../../../blocks/BorderedImage';
import router, { ROUTE_ABOUT } from '../../../router';

function HomepageWhyCoachingMobile() {
  const language = useLanguage();

  return (
    <div className="why-coaching-preview-mobile">
      <div className="why-coaching-preview-text">
        <div className="why-coaching-preview-title">{language.homeWhyCoachingTitle}</div>
        <div className="why-coaching-preview-image-description">
          <div className="why-coaching-preview-image">
            <BorderedImage borderColor="#d4b466" image="/images/why-coaching-03.png" />
          </div>
          <div className="why-coaching-preview-description">
            <div>{language.homeWhyCoaching1}</div>
            <div>{language.homeWhyCoaching2}</div>
            <div>{language.homeWhyCoaching3}</div>
            <button onClick={() => router.navigate(ROUTE_ABOUT)}>{language.readMoreButton}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomepageWhyCoachingMobile;
