import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { RouterProvider } from 'react-router5';
import App from './pages/App';
import LanguageProvider from './languages/LanguageProvider';
import router from './router';
import '@fortawesome/fontawesome-pro/css/all.css';
import './index.scss';

render(
  <StrictMode>
    <RouterProvider router={router}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </RouterProvider>
  </StrictMode>,
  document.getElementById('root'),
);
