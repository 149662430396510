import React from 'react';
import './StepThree.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import TextInput from '../forms/TextInput';

function StepThreeMobile() {
  const language = useLanguage();

  return (
    <div className="step-three-content-mobile">
      <div className="step-three-title-mobile">{language.stepThreeTitle}</div>
      <div className="full-contact-form-intro-mobile">{language.stepThreeIntro}</div>
      <div className="full-contact-form-mobile">
        <div className="name-mobile">
          <div className="first-name-mobile">
            <TextInput id="first-name" placeholder={language.contactFirstName} required />
          </div>
          <div className="last-name-mobile">
            <TextInput id="last-name" placeholder={language.contactLastName} />
          </div>
        </div>
        <div className="contact-mobile">
          <div className="email-mobile">
            <TextInput id="email" placeholder={language.contactEmail} required />
          </div>
          <div className="phone-mobile">
            <TextInput type="tel" id="phone-number" placeholder={language.contactPhone} />
          </div>
          <div className="location-mobile">
            <TextInput id="location" placeholder={language.contactLocation} required />
          </div>
          <div className="contact-options-mobile">
            <fieldset>
              <legend>{language.contactOptionsTitle}</legend>
              <div className="contact-platforms-mobile">
                <div>
                  <input type="checkbox" id="by-email" name="by-email" />
                  <label htmlFor="by-email">{language.contactOptionsEmail}</label>
                </div>
                <div>
                  <input type="checkbox" id="phone" name="phone" />
                  <label htmlFor="phone">{language.contactOptionsPhone}</label>
                </div>
                <div>
                  <input type="checkbox" id="whatsapp" name="whatsapp" />
                  <label htmlFor="whatsapp">{language.contactOptionsWhatsapp}</label>
                </div>
                <div>
                  <input type="checkbox" id="signal" name="signal" />
                  <label htmlFor="signal">{language.contactOptionsSignal}</label>
                </div>
                <div>
                  <input type="checkbox" id="telegram" name="telegram" />
                  <label htmlFor="telegram">{language.contactOptionsTelegram}</label>
                </div>
              </div>
              <div className="contact-days-mobile">
                <div>
                  <input type="checkbox" id="monday" name="monday" />
                  <label htmlFor="monday">{language.contactDayMonday}</label>
                </div>
                <div>
                  <input type="checkbox" id="tuesday" name="tuesday" />
                  <label htmlFor="tuesday">{language.contactDayTuesday}</label>
                </div>
                <div>
                  <input type="checkbox" id="wednesday" name="wednesday" />
                  <label htmlFor="wednesday">{language.contactDayWednesday}</label>
                </div>
                <div>
                  <input type="checkbox" id="thursday" name="thursday" />
                  <label htmlFor="thursday">{language.contactDayThursday}</label>
                </div>
                <div>
                  <input type="checkbox" id="friday" name="friday" />
                  <label htmlFor="friday">{language.contactDayFriday}</label>
                </div>
                <div>
                  <input type="checkbox" id="saturday" name="saturday" />
                  <label htmlFor="saturday">{language.contactDaySaturday}</label>
                </div>
                <div>
                  <input type="checkbox" id="sunday" name="sunday" />
                  <label htmlFor="sunday">{language.contactDaySunday}</label>
                </div>
              </div>
              <div className="contact-time-mobile">
                <div>
                  <input type="checkbox" id="morning" name="morning" />
                  <label htmlFor="morning">{language.contactTimeMorning}</label>
                </div>
                <div>
                  <input type="checkbox" id="afternoon" name="afternoon" />
                  <label htmlFor="afternoon">{language.contactTimeAfternoon}</label>
                </div>
                <div>
                  <input type="checkbox" id="evening" name="evening" />
                  <label htmlFor="evening">{language.contactTimeEvening}</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="further-info-mobile">
          <div className="previous-coaching-mobile">
            <fieldset>
              <legend>{language.contactPreviousCoachingTitle}</legend>
              <div>
                <input type="radio" id="yes" name="previous-coaching" value="yes" />
                <label htmlFor="yes">{language.contactPreviousCoachingYes}</label>
              </div>
              <div>
                <input type="radio" id="meh" name="previous-coaching" value="meh" />
                <label htmlFor="meh">{language.contactPreviousCoachingMeh}</label>
              </div>
              <div>
                <input type="radio" id="no" name="previous-coaching" value="no" />
                <label htmlFor="no">{language.contactPreviousCoachingNo}</label>
              </div>
              <div>
                <input type="radio" id="nope" name="previous-coaching" value="nope" />
                <label htmlFor="nope">{language.contactPreviousCoachingNope}</label>
              </div>
            </fieldset>
          </div>
          <div className="current-coaching-mobile">
            <textarea id="current-coaching" name="current-coaching" placeholder={language.contactCurrentCoaching} />
          </div>
          <div className="fun-fact-mobile">
            <textarea id="fun-fact" name="fun-fact" placeholder={language.contactFunFact} />
          </div>
        </div>
        <div className="submit-form-button-mobile">
          <button type="submit">{language.submit}</button>
        </div>
      </div>
    </div>
  );
}

export default StepThreeMobile;
