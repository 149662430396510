import React from 'react';
import PropTypes from 'prop-types';
import './TestimonialPreview.scss';

function TestimonialPreview({ activePageIndex, pageIndex, maxPageIndex, name, text, image, location }) {
  const leftIndex = activePageIndex === 0 ? maxPageIndex : activePageIndex - 1;
  const rightIndex = activePageIndex === maxPageIndex ? 0 : activePageIndex + 1;

  const isLeft = leftIndex === pageIndex;
  const isMiddle = activePageIndex === pageIndex;
  const isRight = rightIndex === pageIndex;
  const isHidden = !isLeft && !isMiddle && !isRight;

  let className = 'testimonials-preview';
  if (isLeft) className += ' left';
  if (isMiddle) className += ' middle';
  if (isRight) className += ' right';
  if (isHidden) className += ' hidden';

  return (
    <div className={className}>
      <div className="top">
        <i className="fal fa-quote-left" />
        {text}
        <i className="fal fa-quote-right" />
      </div>
      <div className="bottom">
        <div className="photo-name-location">
          <img className="photo" src={image} />
          <div className="name-location">
            <div className="name">{name}</div>
            <div className="location">{location}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

TestimonialPreview.propTypes = {
  activePageIndex: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  maxPageIndex: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default TestimonialPreview;
