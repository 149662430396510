import React, { useEffect } from 'react';
import { useRoute } from 'react-router5';
import LandingPage from './landing-page/LandingPage';
import useIsMobile from '../hookers/useIsMobile';
import ComingSoonMobile from './coming-soon/ComingSoon.mobile';
import ComingSoon from './coming-soon/ComingSoon';
import Homepage from './homepage/Homepage';
import HomepageMobile from './homepage/Homepage.mobile';
import TestimonialsFullpage from './testimonials/TestimonialsFullpage';
import TestimonialsFullpageMobile from './testimonials/TestimonialsFullpage.mobile';
import About from './about/About';
import AboutMobile from './about/About.mobile';
import ActionPlans from './action-plans/ActionPlans';
import WorkWithMe from './work-with-me/WorkWithMe';
import ActionPlansMobile from './action-plans/ActionPlans.mobile';
import WorkWithMeMobile from './work-with-me/WorkWithMe.mobile';
import {
  ROUTE_ABOUT,
  ROUTE_ACTION_PLANS,
  ROUTE_BLOG,
  ROUTE_BLOG_POST,
  ROUTE_COMING_SOON,
  ROUTE_HOMEPAGE,
  ROUTE_LANDING,
  ROUTE_PRICES,
  ROUTE_PRIVACY_STATEMENT,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_TESTIMONIALS,
  ROUTE_WORK_WITH_ME,
} from '../router';
import Modal from '../blocks/Modal';
import useLanguage from '../languages/useLanguage';
import PrivacyStatement from './privacy-statement/PrivacyStatement';
import PrivacyStatementMobile from './privacy-statement/PrivacyStatement.mobile';
import TermsAndConditions from './terms-and-conditions/TermsAndConditions';
import TermsAndConditionsMobile from './terms-and-conditions/TermsAndConditions.mobile';
import PriceListMobile from './prices/PriceList.mobile';
import PriceList from './prices/PriceList';
import BlogMobile from './blog/Blog.mobile';
import Blog from './blog/Blog';
import BlogPost from './blog/BlogPost';
import BlogPostMobile from './blog/BlogPost.mobile';

function getComponent(route, isMobile) {
  const name = route.route?.name;
  const params = route.route?.params ?? {};

  switch (name) {
    case ROUTE_ABOUT:
      return isMobile ? <AboutMobile /> : <About />;

    case ROUTE_ACTION_PLANS:
      return isMobile ? <ActionPlansMobile jumpTo={params.id} /> : <ActionPlans jumpTo={params.id} />;

    case ROUTE_BLOG:
      return isMobile ? <BlogMobile /> : <Blog />;

    case `${ROUTE_BLOG}.${ROUTE_BLOG_POST}`:
      return isMobile ? <BlogPostMobile path={params.postPath} /> : <BlogPost path={params.postPath} />;

    case ROUTE_COMING_SOON:
      return isMobile ? <ComingSoonMobile /> : <ComingSoon />;

    case ROUTE_HOMEPAGE:
      return isMobile ? <HomepageMobile /> : <Homepage />;

    case ROUTE_LANDING:
      return <LandingPage />;

    case ROUTE_PRICES:
      return isMobile ? <PriceListMobile /> : <PriceList />;

    case ROUTE_PRIVACY_STATEMENT:
      return isMobile ? <PrivacyStatementMobile /> : <PrivacyStatement />;

    case ROUTE_TERMS_AND_CONDITIONS:
      return isMobile ? <TermsAndConditionsMobile /> : <TermsAndConditions />;

    case ROUTE_TESTIMONIALS:
      return isMobile ? <TestimonialsFullpageMobile /> : <TestimonialsFullpage />;

    case ROUTE_WORK_WITH_ME:
      return isMobile ? <WorkWithMeMobile /> : <WorkWithMe />;

    default:
      setTimeout(() => route.router.navigate(ROUTE_HOMEPAGE), 500);
      return null;
  }
}

function App() {
  const route = useRoute();
  const isMobile = useIsMobile();
  const language = useLanguage();

  const routeName = route.route?.name ?? '';
  useEffect(() => {
    if (routeName === `${ROUTE_BLOG}.${ROUTE_BLOG_POST}`) {
      document.title = `blog | ${language.websiteTitle}`;
      return;
    }

    const lowercaseTitle = routeName
      .split(/([A-Z][a-z]*)/g)
      .join(' ')
      .trim()
      .toLowerCase();

    document.title = `${lowercaseTitle} | ${language.websiteTitle}`;
  }, [language, routeName]);

  useEffect(() => {
    if (window.location.host !== 'akimi.space' && !window.location.host.startsWith('localhost')) {
      window.location.href = `https://akimi.space/`;
    }
  }, []);

  return (
    <div className="main">
      {getComponent(route, isMobile)}
      <Modal key="modal" />
    </div>
  );
}

export default App;
