import React from 'react';
import './About.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import useLanguage from '../../languages/useLanguage';
import ContentBox from '../../blocks/ContentBox';
import AboutCoaching from './sections/AboutCoaching';
import AboutMe from './sections/AboutMe';
import Page from '../../blocks/Page';

function About() {
  const language = useLanguage();

  return (
    <Page className="about-page">
      <Navbar />
      <div className="about-page-content">
        <ContentBox side="right" title={language.aboutCoaching}>
          <AboutCoaching />
        </ContentBox>
        <ContentBox className="about-me" side="left" title={language.aboutMe}>
          <AboutMe />
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

export default About;
