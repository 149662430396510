import React, { useEffect, useState } from 'react';
import useActiveLanguage from '../languages/useActiveLanguage';
import NavbarItems from './NavbarItems';
import './Navbar.scss';
import useLanguage from '../languages/useLanguage';
import useSetLanguage from '../languages/useSetLanguage';
import { useRouter } from 'react-router5';
import { NAVBAR_ELEMENT_ID, SCROLL_CONTENT_ELEMENT_ID } from '../constants';
import {
  ROUTE_ABOUT,
  ROUTE_ACTION_PLANS,
  ROUTE_BLOG,
  ROUTE_HOMEPAGE,
  ROUTE_PRICES,
  ROUTE_TESTIMONIALS,
  ROUTE_WORK_WITH_ME,
} from '../router';

function Navbar() {
  const activeLanguage = useActiveLanguage();
  const language = useLanguage();
  const router = useRouter();
  const setLanguage = useSetLanguage();
  const [isSmallHeader, setSmallHeader] = useState(false);

  useEffect(() => {
    const scrollElement = document.getElementById(SCROLL_CONTENT_ELEMENT_ID);
    const handler = () => setSmallHeader(scrollElement.scrollTop > 80);

    scrollElement.addEventListener('scroll', handler);
    return () => scrollElement.removeEventListener('scroll', handler);
  }, []);

  function handleEnglishClick() {
    setLanguage('English');
  }

  function handleFrenchClick() {
    setLanguage('French');
  }

  const englishClassName = activeLanguage === 'English' ? '' : 'disabled';
  const frenchClassName = activeLanguage === 'French' ? '' : 'disabled';

  const navbarClassName = isSmallHeader ? 'navbar small' : 'navbar';

  return (
    <nav className={navbarClassName} id={NAVBAR_ELEMENT_ID}>
      <div className="navbar-logo">
        <img alt="logo akimi white" onClick={() => router.navigate(ROUTE_HOMEPAGE)} src="/images/logo-akimi-long.png" />
      </div>
      <div className="navbar-links">
        <NavbarItems onClick={() => router.navigate(ROUTE_WORK_WITH_ME)}>{language.navbarWork}</NavbarItems>
        <NavbarItems onClick={() => router.navigate(ROUTE_ACTION_PLANS)}>{language.navbarActionPlans}</NavbarItems>
        <NavbarItems onClick={() => router.navigate(ROUTE_PRICES)}>{language.navbarPrices}</NavbarItems>
        <NavbarItems onClick={() => router.navigate(ROUTE_ABOUT)}>{language.navbarAbout}</NavbarItems>
        <NavbarItems onClick={() => router.navigate(ROUTE_TESTIMONIALS)}>{language.navbarTestimonials}</NavbarItems>
        {/* <NavbarItems>{language.navbarClients}</NavbarItems> */}
        <NavbarItems onClick={() => router.navigate(ROUTE_BLOG)}>{language.navbarBlog}</NavbarItems>
        <div className="navbar-languages">
          <img alt="English" className={englishClassName} onClick={handleEnglishClick} src="/images/flag-english.png" />
          <img alt="French" className={frenchClassName} onClick={handleFrenchClick} src="/images/flag-french.png" />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
