import { createRouter } from 'router5';
import browserPlugin from 'router5-plugin-browser';

export const ROUTE_ABOUT = 'about';
export const ROUTE_ACTION_PLANS = 'actionPlans';
export const ROUTE_BLOG = 'blog';
export const ROUTE_BLOG_POST = 'blog-post';
export const ROUTE_COOKIES_POLICY = 'cookiesPolicy';
export const ROUTE_COMING_SOON = 'comingSoon';
export const ROUTE_HOMEPAGE = 'homepage';
export const ROUTE_LANDING = 'landing';
export const ROUTE_PRICES = 'prices';
export const ROUTE_PRIVACY_STATEMENT = 'privacyStatement';
export const ROUTE_TERMS_AND_CONDITIONS = 'termsAndConditions';
export const ROUTE_TESTIMONIALS = 'testimonials';
export const ROUTE_WORK_WITH_ME = 'workWithMe';

export const routes = [
  { name: ROUTE_ABOUT, path: '/about' },
  { name: ROUTE_ACTION_PLANS, path: '/action-plans' },
  {
    name: ROUTE_BLOG,
    path: '/blog',
    children: [{ name: ROUTE_BLOG_POST, path: '/:postPath' }],
  },
  {
    name: 'clientSpace',
    path: '/client-space',
    children: [
      { name: 'guidelines', path: '/guidelines' },
      { name: 'nextSession', path: '/schedule-next-session' },
      { name: 'register', path: '/register' },
      { name: 'yourSpace', path: '/your-space' },
    ],
  },
  { name: ROUTE_COMING_SOON, path: '/coming-soon' },
  { name: ROUTE_COOKIES_POLICY, path: '/cookies-policy' },
  { name: ROUTE_HOMEPAGE, path: '/home' },
  { name: ROUTE_LANDING, path: '/' },
  { name: ROUTE_PRICES, path: '/prices' },
  { name: ROUTE_PRIVACY_STATEMENT, path: '/privacy-statement' },
  { name: ROUTE_TERMS_AND_CONDITIONS, path: '/terms-and-conditions' },
  { name: ROUTE_TESTIMONIALS, path: '/testimonials' },
  { name: ROUTE_WORK_WITH_ME, path: '/work-with-me' },
];

const router = createRouter(routes, {
  defaultRoute: 'defaultRoute',
  defaultParams: {},
  trailingSlashMode: 'default',
  strictTrailingSlash: false,
  caseSensitive: false,
});

router.usePlugin(browserPlugin());
router.start();

export default router;
