export const NAVBAR_ELEMENT_ID = 'navbar';
export const SCROLL_CONTENT_ELEMENT_ID = 'scroll-content';

export const PLAN_RESILIENCE_ELEMENT_ID = 'action-plan-resilience';
export const PLAN_PLAN_ELEMENT_ID = 'action-plan-plan';
export const PLAN_FLOW_ELEMENT_ID = 'action-plan-flow';

export const WWM_ACTION_PLAN_RESILIENCE = 'resilience';
export const WWM_ACTION_PLAN_AHEAD = 'ahead';
export const WWM_ACTION_PLAN_FLOW = 'flow';

export const WWM_PACKAGE_EXPRESS = 'express';
export const WWM_PACKAGE_LITE = 'lite';
export const WWM_PACKAGE_FULL = 'full';
export const WWM_PACKAGE_BEYOND = 'beyond';
