import React from 'react';
import './PriceList.scss';
import useLanguage from '../../languages/useLanguage';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import Page from '../../blocks/Page';
import ContentBox from '../../blocks/ContentBox';
import Tooltip from '../work-with-me/forms/Tooltip';

function PriceList() {
  const language = useLanguage();

  return (
    <Page className="price-list-page">
      <Navbar />
      <div className="price-list-content">
        <ContentBox side="right" title={language.pricesTitle}>
          <div className="prices-introduction">
            <div>{language.pricesIntroduction1}</div>
            <div>{language.pricesIntroduction2}</div>
          </div>
          <div className="packages">
            <div className="choose-express-lite">
              <div className="choose-package-option package-express">
                <div className="ribbon">
                  <span>{language.packageExpress}</span>
                </div>
                <div className="package-info">
                  <div>
                    <div className="package-element-largest">
                      <div>{language.packageTotal6sessions}</div>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionsRegular}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                  </div>
                  <div className="package-price-group circle-sketch-1">
                    <div className="price-package">{language.packageExpressTotal}</div>
                    <div className="price-session">
                      <div>{language.packageExpressSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-package-option package-lite">
                <div className="ribbon">
                  <span>{language.packageLite}</span>
                </div>
                <div className="package-info">
                  <div>
                    <div className="package-element-largest">
                      <div>{language.packageTotal10sessions}</div>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessions9Sessions}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                  </div>
                  <div className="package-price-group circle-sketch-4">
                    <div className="price-package">{language.packageLiteTotal}</div>
                    <div className="price-session">
                      <div>{language.packageLiteSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="choose-full-beyond">
              <div className="choose-package-option package-full">
                <div className="ribbon">
                  <span>{language.packageFull}</span>
                </div>
                <div className="package-info">
                  <div>
                    <div className="package-element-largest">
                      <div>{language.packageTotal6sessions}</div>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessions4Sessions}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionClosing}</div>
                      <Tooltip>
                        {language.tooltipSessionClosing1} <br />
                        {language.tooltipSessionClosing2} <br />
                        {language.tooltipSessionClosing3} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large">
                      <div>{language.packageTouchdown}</div>
                      <Tooltip>
                        {language.tooltipTouchdowns1} <br />
                        {language.tooltipTouchdowns2} <br />
                        {language.tooltipTouchdowns3} <br />
                        {language.tooltipTouchdowns4} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large">
                      <div>{language.packageReports}</div>
                      <Tooltip>
                        {language.tooltipReports1} <br />
                        {language.tooltipReports2} <br />
                        {language.tooltipReports3} <br />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="package-price-group circle-sketch-3">
                    <div className="price-package">{language.packageFullTotal}</div>
                    <div className="price-session">
                      <div>{language.packageFullSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-package-option package-beyond">
                <div className="ribbon">
                  <span>{language.packageBeyond}</span>
                </div>
                <div className="package-info">
                  <div>
                    <div className="package-element-largest">
                      <div>{language.packageTotal10sessions}</div>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionsRegularPlus}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                    <div className="package-element-small">
                      <div>{language.packageSessionClosing}</div>
                      <Tooltip>
                        {language.tooltipSessionClosing1} <br />
                        {language.tooltipSessionClosing2} <br />
                        {language.tooltipSessionClosing3} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large">
                      <div>{language.packageTouchdown}</div>
                      <Tooltip>
                        {language.tooltipTouchdowns1} <br />
                        {language.tooltipTouchdowns2} <br />
                        {language.tooltipTouchdowns3} <br />
                        {language.tooltipTouchdowns4} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large">
                      <div>{language.packageReports}</div>
                      <Tooltip>
                        {language.tooltipReports1} <br />
                        {language.tooltipReports2} <br />
                        {language.tooltipReports3} <br />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="package-price-group circle-sketch-2">
                    <div className="price-package">{language.packageBeyondTotal}</div>
                    <div className="price-session">
                      <div>{language.packageBeyondSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="prices-faq">
            <div>
              <div className="question">{language.pricesFAQPackagesTitle}</div>
              <div className="answer">{language.pricesFAQPackages1}</div>
              <div className="answer">{language.pricesFAQPackages2}</div>
              <div className="answer">{language.pricesFAQPackages3}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQFrequencyTitle}</div>
              <div className="answer">{language.pricesFAQFrequency1}</div>
              <div className="answer">{language.pricesFAQFrequency2}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQBundleTitle}</div>
              <div className="answer">{language.pricesFAQBundle1}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQInstalmentsTitle}</div>
              <div className="answer">{language.pricesFAQInstalments1}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQDiscountsTitle}</div>
              <div className="answer">{language.pricesFAQDiscounts1}</div>
              <div className="answer">{language.pricesFAQDiscounts2}</div>
            </div>
          </div>
        </ContentBox>
      </div>
      <Footer />
    </Page>
  );
}

export default PriceList;
