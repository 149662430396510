import React from 'react';
import './Blog.scss';
import './BlogPost.scss';
import Navbar from '../../blocks/Navbar';
import Footer from '../../blocks/Footer';
import Page from '../../blocks/Page';
import PropTypes from 'prop-types';
import getBlogPost from './getBlogPost';

function BlogPost({ path }) {
  return (
    <Page className="blog-page">
      <Navbar />
      <div className="blog-page-content">{getBlogPost(path)}</div>
      <Footer />
    </Page>
  );
}

BlogPost.propTypes = {
  path: PropTypes.string,
};

export default BlogPost;
