import React, { useEffect, useRef, useState } from 'react';
import './HomepageTestimonials.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import PageIndicator from '../../../blocks/PageIndicator';
import TestimonialPreviewMobile from './TestimonialPreview.mobile';
import router, { ROUTE_TESTIMONIALS } from '../../../router';

function HomepageTestimonialsMobile() {
  const language = useLanguage();
  const [requestedTestimonialIndex, setRequestedTestimonialIndex] = useState(undefined);
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  /**
   * interface TouchData {
   *   direction: 'left' | 'right' | 'none';
   *   start?: { x: number, y: number };
   * }
   */
  const touchDataRef = useRef({ start: undefined, direction: 'none' });

  const testimonials = [
    {
      name: 'Jean-Baptiste',
      text: language.homeTestimonialPreviewJB,
      image: '/images/jeanbaptiste.jpg',
      location: language.countryFrance,
    },
    {
      name: 'Sarah',
      text: language.homeTestimonialPreviewSarah,
      image: '/images/sarah.jpg',
      location: language.countryNetherlands,
    },
    {
      name: 'Sia',
      text: language.homeTestimonialPreviewSia,
      image: '/images/sia.jpg',
      location: language.countryUK,
    },
    {
      name: 'Katherina',
      text: language.homeTestimonialPreviewKatherina,
      image: '/images/katherina.jpg',
      location: language.countryNetherlands,
    },
    {
      name: 'Christina',
      text: language.homeTestimonialPreviewChristina,
      image: '/images/christina.jpg',
      location: language.countryUK,
    },
    {
      name: 'Franzi',
      text: language.homeTestimonialPreviewFranzi,
      image: '/images/franzi.jpg',
      location: language.countryGermany,
    },
    {
      name: 'Simon',
      text: language.homeTestimonialPreviewSimon,
      image: '/images/simon.jpg',
      location: language.countryNetherlands,
    },
  ];

  const leftIndex = testimonialIndex === 0 ? testimonials.length - 1 : testimonialIndex - 1;
  const rightIndex = testimonialIndex === testimonials.length - 1 ? 0 : testimonialIndex + 1;

  const testimonialsLength = testimonials.length;

  useEffect(() => {
    const autoCycleTimeout = setTimeout(function () {
      let newTestimonialIndex = testimonialIndex + 1;
      if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
      if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;
      setTestimonialIndex(newTestimonialIndex);
    }, 10_000);

    return function () {
      clearTimeout(autoCycleTimeout);
    };
  }, [testimonialsLength, testimonialIndex]);

  useEffect(() => {
    if (requestedTestimonialIndex === undefined) return;

    if (requestedTestimonialIndex === testimonialIndex) {
      setRequestedTestimonialIndex(undefined);
      return;
    }

    const itemsBetween = Math.abs(testimonialIndex - requestedTestimonialIndex);
    const halfItemsCount = Math.floor(testimonialsLength / 2);
    const isGoingRound = itemsBetween > halfItemsCount;

    if (requestedTestimonialIndex < testimonialIndex) {
      const prevTimeout = setTimeout(function () {
        const offsetAmount = isGoingRound ? 1 : -1;
        let newTestimonialIndex = testimonialIndex + offsetAmount;
        if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
        if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;

        setTestimonialIndex(newTestimonialIndex);
      }, 150);
      return function () {
        clearTimeout(prevTimeout);
      };
    }

    const nextTimeout = setTimeout(function () {
      const offsetAmount = isGoingRound ? -1 : 1;
      let newTestimonialIndex = testimonialIndex + offsetAmount;
      if (newTestimonialIndex === -1) newTestimonialIndex = testimonialsLength - 1;
      if (newTestimonialIndex === testimonialsLength) newTestimonialIndex = 0;

      setTestimonialIndex(newTestimonialIndex);
    }, 150);
    return function () {
      clearTimeout(nextTimeout);
    };
  }, [requestedTestimonialIndex, testimonialIndex, testimonialsLength]);

  const handlePreviousClick = () => {
    setTestimonialIndex(leftIndex);
  };

  const handleNextClick = () => {
    setTestimonialIndex(rightIndex);
  };

  const handleIndicatorClick = (newTestimonialIndex) => {
    setRequestedTestimonialIndex(newTestimonialIndex);
  };

  const handleTouchEnd = (event) => {
    const touch = event.touches[0];
    if (touch === undefined) {
      if (touchDataRef.current.direction === 'left') {
        handleNextClick();
      }

      if (touchDataRef.current.direction === 'right') {
        handlePreviousClick();
      }

      touchDataRef.current.start = undefined;
      touchDataRef.current.direction = 'none';
    }
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    if (touch !== undefined && touchDataRef.current.start !== undefined) {
      const yDelta = touch.clientY - touchDataRef.current.start.y;
      const xDelta = touch.clientX - touchDataRef.current.start.x;

      const isHorizontal = Math.abs(yDelta) < Math.abs(xDelta) * 0.5;
      if (!isHorizontal) {
        touchDataRef.current.direction = 'none';
        return;
      }

      if (xDelta < 0) touchDataRef.current.direction = 'left';
      else touchDataRef.current.direction = 'right';
    }
  };

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    if (touch !== undefined) {
      touchDataRef.current.start = { x: touch.clientX, y: touch.clientY };
    }
  };

  return (
    <div
      className="home-testimonials-mobile"
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <div className="testimonials-preview-title">{language.homeTestimonialsTitleMobile}</div>
      <div className="testimonials-preview-carousel">
        <i className="fad fa-chevron-left" onClick={handlePreviousClick} />
        {testimonials.map(function (testimonial, pageIndex) {
          return (
            <TestimonialPreviewMobile
              key={pageIndex}
              {...testimonial}
              activePageIndex={testimonialIndex}
              pageIndex={pageIndex}
              maxPageIndex={testimonials.length - 1}
            />
          );
        })}
        <i className="fad fa-chevron-right" onClick={handleNextClick} />
      </div>
      <div className="page-indicators">
        {testimonials.map(function (_, pageIndex) {
          return (
            <PageIndicator
              key={pageIndex}
              activePageIndex={testimonialIndex}
              onClick={handleIndicatorClick}
              pageIndex={pageIndex}
            />
          );
        })}
      </div>
      <button onClick={() => router.navigate(ROUTE_TESTIMONIALS)}>{language.readMoreButton}</button>
    </div>
  );
}

export default HomepageTestimonialsMobile;
