import React from 'react';
import useBlogLanguage from '../../../languages/useBlogLanguage';
import SocialMedia from '../SocialMedia';

function _004_Post() {
  const blogLanguage = useBlogLanguage();

  return (
    <div className="blog-post-page">
      <div className="blog-post-banner">
        <img alt="New, year, new me, new who?" className="blog-post-photo-banner" src="/images/blog-post-004-03.png" />
        <div className="blog-post-title">
          <div>{blogLanguage._004_Title1}</div>
          <div>{blogLanguage._004_Title2}</div>
        </div>
        <div className="banner-bottom-line">
          <div className="post-details">
            <div className="blog-post-date">{blogLanguage._003_Date}</div>
            <div> | </div>
            <div className="blog-topic">{blogLanguage.topicSelfLeadership}</div>
            <div> | </div>
            <div className="blog-reading-time">10 mins</div>
          </div>
          <SocialMedia />
        </div>
      </div>

      <div className="blog-post-content">
        <div className="blog-post-introduction">
          <div>{blogLanguage._004_Introduction1}</div>
          <div>{blogLanguage._004_Introduction2}</div>
          <div>{blogLanguage._004_Introduction3}</div>
        </div>
        <div className="blog-post-paragraphs">
          <div className="blog-heading">
            <div className="blog-heading-text">{blogLanguage._004_SMARTTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_SMART1}</span>
            </div>
            <div>
              <span>{blogLanguage._004_SMART2}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_SpecificTitle}</div>
          </div>
          <div className="blog-paragraph-text-image">
            <div className="blog-paragraph-text">
              <div>
                <span>{blogLanguage._004_Specific1}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Specific2}</span>
                <span>{blogLanguage._004_Specific3}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Specific4}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Specific5}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Specific6}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Specific7}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Specific8}</span>
              </div>
            </div>
            <img alt="SMART goal: specific" className="blog-paragraph-image-right" src="/images/SMART-002.png" />
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_Specific9}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Specific10a}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._004_Specific10b}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Specific11}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Specific12}</span>
              <span>{blogLanguage._004_Specific13}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_MeasurableTitle}</div>
          </div>
          <div className="blog-paragraph-text-image">
            <div>
              <div>
                <span>{blogLanguage._004_Measurable1}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Measurable2}</span>
                <span>{blogLanguage._004_Measurable3}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Measurable4}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Measurable5}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Measurable6}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Measurable7}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Measurable8}</span>
              </div>
            </div>
            <img alt="SMART goal: measurable" className="blog-paragraph-image-right" src="/images/SMART-003.png" />
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_Measurable9}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Measurable10}</span>
              <span>{blogLanguage._004_Measurable11}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Measurable12}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_Measurable13}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_Measurable14}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_Measurable15}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Measurable16}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_Measurable17}</span>
              <span>{blogLanguage._004_Measurable18}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_AttainableTitle}</div>
          </div>
          <div className="blog-paragraph-text-image">
            <div className="blog-paragraph-text">
              <div>
                <span>{blogLanguage._004_Attainable1}</span>
                <span className="highlighted-sketch">{blogLanguage._004_Attainable2}</span>
                <span>{blogLanguage._004_Attainable3}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Attainable4}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Attainable5}</span>
                <span>{blogLanguage._004_Attainable6}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Attainable7}</span>
                <span className="highlighted-sketch">{blogLanguage._004_Attainable8}</span>
                <span>{blogLanguage._004_Attainable9}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Attainable10}</span>
              </div>
            </div>
            <img alt="SMART goal: attainable" className="blog-paragraph-image-right" src="/images/SMART-004.png" />
          </div>
          <div>
            <div>
              <div>
                <span>{blogLanguage._004_Attainable11}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Attainable12}</span>
                <span>{blogLanguage._004_Attainable13}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Attainable14}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Attainable15}</span>
              </div>
              <div className="important-sentence">
                <span>{blogLanguage._004_Attainable16}</span>
              </div>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_RelevantTitle}</div>
          </div>
          <div className="blog-paragraph-text-image">
            <div className="blog-paragraph-text">
              <div>
                <span>{blogLanguage._004_Relevant1}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Relevant2}</span>
                <span>{blogLanguage._004_Relevant3}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Relevant4}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Relevant5}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Relevant6}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Relevant7}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Relevant8}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Relevant9}</span>
              </div>
            </div>
            <img alt="SMART goal: relevant" className="blog-paragraph-image-right" src="/images/SMART-005.png" />
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_Relevant10}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Relevant11}</span>
              <span>{blogLanguage._004_Relevant12}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Relevant13}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_Relevant14}</span>
              <span>{blogLanguage._004_Relevant15}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Relevant16}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_TimeTitle}</div>
          </div>
          <div className="blog-paragraph-text-image">
            <div className="blog-paragraph-text">
              <div>
                <span>{blogLanguage._004_Time1}</span>
                <span className="highlighted-sketch">{blogLanguage._004_Time2}</span>
                <span>{blogLanguage._004_Time3}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Time4}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Time5}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Time6}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Time7}</span>
                <span className="highlighted-sketch-reverse">{blogLanguage._004_Time8}</span>
                <span>{blogLanguage._004_Time9}</span>
              </div>
              <div>
                <span>{blogLanguage._004_Time10}</span>
              </div>
              <div className="blog-bullet-point">
                <span>{blogLanguage._004_Time12}</span>
              </div>
            </div>
            <img alt="SMART goal: time-specific" className="blog-paragraph-image-right" src="/images/SMART-006.png" />
          </div>
          <div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_Time11}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Time13}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Time14}</span>
              <span>{blogLanguage._004_Time15}</span>
            </div>
          </div>

          <div className="blog-heading">
            <div className="blog-heading-text">{blogLanguage._004_MoreThingsTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_MoreThings1}</span>
            </div>
            <div>
              <span>{blogLanguage._004_MoreThings2}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_CreateSpaceTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_CreateSpace1}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_CreateSpace2}</span>
              <span>{blogLanguage._004_CreateSpace3}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace4}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace5}</span>
              <span className="highlighted-sketch">{blogLanguage._004_CreateSpace6}</span>
              <span>{blogLanguage._004_CreateSpace7}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace8}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace9}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_CreateSpace10}</span>
              <span>{blogLanguage._004_CreateSpace11}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace12}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_CreateSpace13}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_CreateSpace14}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_CreateSpace15}</span>
            </div>
            <div className="blog-bullet-point">
              <span>{blogLanguage._004_CreateSpace16}</span>
            </div>
            <div>
              <span>{blogLanguage._004_CreateSpace17}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_WillpowerTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_Willpower1}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Willpower2}</span>
              <span>{blogLanguage._004_Willpower3}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Willpower4}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Willpower5}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Willpower6}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._004_Willpower7}</span>
              <span>{blogLanguage._004_Willpower8}</span>
              <span>{blogLanguage._004_Willpower9}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Willpower10}</span>
              <span className="highlighted-sketch">{blogLanguage._004_Willpower11}</span>
              <span>{blogLanguage._004_Willpower12}</span>
            </div>
            <div>
              <span>{blogLanguage._004_Willpower13}</span>
            </div>
          </div>

          <div className="blog-subtitle">
            <div className="blog-subtitle-bullet">
              <i className="fad fa-chevron-double-right" />
            </div>
            <div className="blog-subtitle-text">{blogLanguage._004_ListenNeedsTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._004_ListenNeeds1}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_ListenNeeds2}</span>
              <span>{blogLanguage._004_ListenNeeds3}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds4}</span>
              <span className="highlighted-sketch">{blogLanguage._004_ListenNeeds5}</span>
              <span>{blogLanguage._004_ListenNeeds6}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds7}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds8}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds9}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_ListenNeeds10}</span>
              <span>{blogLanguage._004_ListenNeeds11}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds12}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds13}</span>
              <span className="highlighted-sketch">{blogLanguage._004_ListenNeeds14}</span>
              <span>{blogLanguage._004_ListenNeeds15}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds16}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds17}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds18}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._004_ListenNeeds19}</span>
              <span>{blogLanguage._004_ListenNeeds20}</span>
            </div>
            <div>
              <span>{blogLanguage._004_ListenNeeds21}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._004_ListenNeeds22}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default _004_Post;
