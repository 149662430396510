import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OptionalBlogCard, { BOTTOM_TO_TOP, LEFT_TO_RIGHT, RIGHT_TO_LEFT, TOP_TO_BOTTOM } from './OptionalBlogCard';
import './PostTiles.scss';

const Container = styled.div`
  position: relative;
`;

const getGridTemplateAreas = (postCount) => {
  if (postCount > 16) {
    return `
      'card-1   card-2    card-2    card-4    card-4'
      'card-1   card-3    card-3    card-8    card-9'
      'card-5   card-6    card-7    card-8    card-9'
      'card-5   card-6    card-7    card-10   card-10'
      'card-11  card-12   card-12   card-13   card-14'
      'card-11  card-15   card-16   card-13   card-14'
      'card-17  card-15   card-16   card-18   card-18'
      'card-17  card-19   card-19   card-20   card-20'
    `;
  }

  if (postCount > 14) {
    return `
      'card-1   card-2    card-2    card-4    card-4'
      'card-1   card-3    card-3    card-8    card-9'
      'card-5   card-6    card-7    card-8    card-9'
      'card-5   card-6    card-7    card-10   card-10'
      'card-11  card-12   card-12   card-13   card-14'
      'card-11  card-15   card-16   card-13   card-14'
      'card-17  card-15   card-16   card-18   card-18'
    `;
  }

  if (postCount > 10) {
    return `
      'card-1   card-2    card-2    card-4    card-4'
      'card-1   card-3    card-3    card-8    card-9'
      'card-5   card-6    card-7    card-8    card-9'
      'card-5   card-6    card-7    card-10   card-10'
      'card-11  card-12   card-12   card-13   card-14'
      'card-11  card-15   card-16   card-13   card-14'
    `;
  }

  if (postCount > 4) {
    return `
      'card-1   card-2    card-2    card-4    card-4'
      'card-1   card-3    card-3    card-8    card-9'
      'card-5   card-6    card-7    card-8    card-9'
      'card-5   card-6    card-7    card-10   card-10'
    `;
  }

  return `
    'card-1   card-2    card-2    card-4    card-4'
    'card-1   card-3    card-3    card-8    card-9'
  `;
};

function PostTiles({ posts }) {
  return (
    <Container className="post-tiles" style={{ gridTemplateAreas: getGridTemplateAreas(posts.length) }}>
      <OptionalBlogCard blogInfo={posts[0]} direction={TOP_TO_BOTTOM} gridArea="card-1" />
      <OptionalBlogCard blogInfo={posts[1]} direction={LEFT_TO_RIGHT} gridArea="card-2" />
      <OptionalBlogCard blogInfo={posts[2]} direction={RIGHT_TO_LEFT} gridArea="card-3" />
      <OptionalBlogCard blogInfo={posts[3]} direction={LEFT_TO_RIGHT} gridArea="card-4" />
      <OptionalBlogCard blogInfo={posts[4]} direction={BOTTOM_TO_TOP} gridArea="card-5" />

      <OptionalBlogCard blogInfo={posts[5]} direction={TOP_TO_BOTTOM} gridArea="card-6" />
      <OptionalBlogCard blogInfo={posts[6]} direction={BOTTOM_TO_TOP} gridArea="card-7" />
      <OptionalBlogCard blogInfo={posts[7]} direction={BOTTOM_TO_TOP} gridArea="card-8" />
      <OptionalBlogCard blogInfo={posts[8]} direction={TOP_TO_BOTTOM} gridArea="card-9" />
      <OptionalBlogCard blogInfo={posts[9]} direction={RIGHT_TO_LEFT} gridArea="card-10" />

      <OptionalBlogCard blogInfo={posts[10]} direction={TOP_TO_BOTTOM} gridArea="card-11" />
      <OptionalBlogCard blogInfo={posts[11]} direction={LEFT_TO_RIGHT} gridArea="card-12" />
      <OptionalBlogCard blogInfo={posts[12]} direction={TOP_TO_BOTTOM} gridArea="card-13" />
      <OptionalBlogCard blogInfo={posts[13]} direction={BOTTOM_TO_TOP} gridArea="card-14" />
      <OptionalBlogCard blogInfo={posts[14]} direction={BOTTOM_TO_TOP} gridArea="card-15" />

      <OptionalBlogCard blogInfo={posts[15]} direction={TOP_TO_BOTTOM} gridArea="card-16" />
      <OptionalBlogCard blogInfo={posts[16]} direction={BOTTOM_TO_TOP} gridArea="card-17" />
      <OptionalBlogCard blogInfo={posts[17]} direction={LEFT_TO_RIGHT} gridArea="card-18" />
      <OptionalBlogCard blogInfo={posts[18]} direction={RIGHT_TO_LEFT} gridArea="card-19" />
      <OptionalBlogCard blogInfo={posts[19]} direction={RIGHT_TO_LEFT} gridArea="card-20" />
    </Container>
  );
}

PostTiles.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PostTiles;
