import React from 'react';
import useBlogLanguage from '../../../languages/useBlogLanguage';
import SocialMedia from '../SocialMedia';

function _001_Post() {
  const blogLanguage = useBlogLanguage();

  return (
    <div className="blog-post-page">
      <div className="blog-post-banner">
        <img
          alt="How to choose a coach that's right for you?"
          className="blog-post-photo-banner"
          src="/images/blog-post-001-01.jpg"
        />
        <div className="blog-post-title">
          <div>{blogLanguage._001_Title1}</div>
          <div>{blogLanguage._001_Title2}</div>
        </div>
        <div className="banner-bottom-line">
          <div className="post-details">
            <div className="blog-post-date">{blogLanguage._001_Date}</div>
            <div> | </div>
            <div className="blog-topic">{blogLanguage.topicTips}</div>
            <div> | </div>
            <div className="blog-reading-time">7 mins</div>
          </div>
          <SocialMedia />
        </div>
      </div>

      <div className="blog-post-content">
        <div className="blog-post-introduction">
          <div>{blogLanguage._001_Introduction1}</div>
          <div>{blogLanguage._001_Introduction2}</div>
        </div>
        <div className="blog-post-paragraphs">
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">1</div>
            <div className="blog-subtitle-text">{blogLanguage._001_WhatYouWantTitle}</div>
          </div>
          <div>
            <span>{blogLanguage._001_WhatYouWant1}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_WhatYouWant2Span}</span>
            <span>{blogLanguage._001_WhatYouWant3}</span>
          </div>
          <div>
            <span>{blogLanguage._001_WhatYouWant4}</span>
            <span className="highlighted-sketch">{blogLanguage._001_WhatYouWant5Span}</span>
            <span>{blogLanguage._001_WhatYouWant6}</span>
          </div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant7}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant8}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant9}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant10}</div>
          <div>{blogLanguage._001_WhatYouWant11}</div>
          <div>
            <span>{blogLanguage._001_WhatYouWant12}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_WhatYouWant13Span}</span>
            <span>{blogLanguage._001_WhatYouWant14}</span>
          </div>
          <div>{blogLanguage._001_WhatYouWant15}</div>
          <div className="important-sentence">{blogLanguage._001_WhatYouWant16}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant17}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant18}</div>
          <div className="blog-bullet-point">{blogLanguage._001_WhatYouWant19}</div>
          <div>
            <span>{blogLanguage._001_WhatYouWant20}</span>
            <span className="highlighted-sketch">{blogLanguage._001_WhatYouWant21Span}</span>
            <span>{blogLanguage._001_WhatYouWant22}</span>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">2</div>
            <div className="blog-subtitle-text">{blogLanguage._001_AskAroundTitle}</div>
          </div>
          <div>{blogLanguage._001_AskAround1}</div>
          <div>
            <span>{blogLanguage._001_AskAround2}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_AskAround3Span}</span>
            <span>{blogLanguage._001_AskAround4}</span>
          </div>
          <div>
            <span>{blogLanguage._001_AskAround5}</span>
            <span className="highlighted-sketch">{blogLanguage._001_AskAround6Span}</span>
            <span>{blogLanguage._001_AskAround7}</span>
          </div>
          <div>
            <span>{blogLanguage._001_AskAround8}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_AskAround9Span}</span>
            <span>{blogLanguage._001_AskAround10}</span>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">3</div>
            <div className="blog-subtitle-text">{blogLanguage._001_ResearchTitle}</div>
          </div>
          <div>{blogLanguage._001_Research1}</div>
          <div>
            <span>{blogLanguage._001_Research2}</span>
            <span className="highlighted-sketch">{blogLanguage._001_Research3Span}</span>
            <span>{blogLanguage._001_Research4}</span>
          </div>
          <div>
            <span>{blogLanguage._001_Research5}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_Research6Span}</span>
            <span>{blogLanguage._001_Research7}</span>
          </div>
          <div>
            <span>{blogLanguage._001_Research8}</span>
            <span className="highlighted-sketch">{blogLanguage._001_Research9Span}</span>
            <span>{blogLanguage._001_Research10}</span>
          </div>
          <div>{blogLanguage._001_Research11}</div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">4</div>
            <div className="blog-subtitle-text">{blogLanguage._001_TheirVibeTitle}</div>
          </div>
          <div>{blogLanguage._001_TheirVibe1}</div>
          <div>{blogLanguage._001_TheirVibe2}</div>
          <div>
            <span>{blogLanguage._001_TheirVibe3}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_TheirVibe4Span}</span>
            <span>{blogLanguage._001_TheirVibe5}</span>
          </div>
          <div>
            <span>{blogLanguage._001_TheirVibe6}</span>
            <span className="highlighted-sketch">{blogLanguage._001_TheirVibe7Span}</span>
            <span>{blogLanguage._001_TheirVibe8}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_TheirVibe9Span}</span>
            <span>{blogLanguage._001_TheirVibe10}</span>
          </div>
          <div>{blogLanguage._001_TheirVibe11}</div>
          <div>
            <span>{blogLanguage._001_TheirVibe12}</span>
            <span className="highlighted-sketch">{blogLanguage._001_TheirVibe13Span}</span>
            <span>{blogLanguage._001_TheirVibe14}</span>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">5</div>
            <div className="blog-subtitle-text">{blogLanguage._001_ChatFirstTitle}</div>
          </div>
          <div>{blogLanguage._001_ChatFirst1}</div>
          <div>
            <span>{blogLanguage._001_ChatFirst2}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_ChatFirst3Span}</span>
            <span>{blogLanguage._001_ChatFirst4}</span>
            <span className="highlighted-sketch">{blogLanguage._001_ChatFirst5Span}</span>
            <span>{blogLanguage._001_ChatFirst6}</span>
          </div>
          <div>{blogLanguage._001_ChatFirst7}</div>
          <div>
            <span>{blogLanguage._001_ChatFirst8}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_ChatFirst9Span}</span>
            <span>{blogLanguage._001_ChatFirst10}</span>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">6</div>
            <div className="blog-subtitle-text">{blogLanguage._001_NeedConnectionTitle}</div>
          </div>
          <div>
            <span>{blogLanguage._001_NeedConnection1}</span>
            <span className="highlighted-sketch">{blogLanguage._001_NeedConnection2Span}</span>
            <span>{blogLanguage._001_NeedConnection3}</span>
          </div>
          <div>{blogLanguage._001_NeedConnection4}</div>
          <div>
            <span>{blogLanguage._001_NeedConnection5}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._001_NeedConnection6Span}</span>
            <span>{blogLanguage._001_NeedConnection7}</span>
          </div>
          <div>{blogLanguage._001_NeedConnection8}</div>
          <div>{blogLanguage._001_NeedConnection9}</div>
          <div className="important-sentence">{blogLanguage._001_NeedConnection10}</div>
        </div>
        <div className="photo-credit">
          <span>{blogLanguage.photoCredit}</span>
          <a href="https://www.instagram.com/lanatomie/" rel="noreferrer" target="_blank">
            lanatomie
          </a>
        </div>
      </div>
    </div>
  );
}

export default _001_Post;
