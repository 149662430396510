import React, { useState } from 'react';
import './StepTwo.mobile.scss';
import useLanguage from '../../../languages/useLanguage';
import { WWM_PACKAGE_BEYOND, WWM_PACKAGE_EXPRESS, WWM_PACKAGE_FULL, WWM_PACKAGE_LITE } from '../../../constants';
import classNames from 'classnames';
import Tooltip from '../forms/Tooltip';

function StepTwoMobile() {
  const language = useLanguage();
  const [selectedPackage, setSelectedPackage] = useState(undefined);

  const selectionMade = selectedPackage !== undefined;
  const isExpressSelected = selectedPackage === WWM_PACKAGE_EXPRESS;
  const isLiteSelected = selectedPackage === WWM_PACKAGE_LITE;
  const isFullSelected = selectedPackage === WWM_PACKAGE_FULL;
  const isBeyondSelected = selectedPackage === WWM_PACKAGE_BEYOND;

  const handleSelection = (newSelectedPackage) => {
    setSelectedPackage(newSelectedPackage);
  };

  return (
    <div className="step-two-content-mobile">
      <div className="step-two-title-mobile">{language.stepTwoTitle}</div>
      <div className="choose-package-intro-mobile">{language.stepTwoIntro}</div>
      <fieldset>
        <legend>{language.stepTwoMeetingOptions}</legend>
        <div className="meeting-frequency-mobile">
          <div>
            <input type="checkbox" id="weekly" name="weekly" />
            <label htmlFor="weekly">{language.stepTwoMeetingFrequency1}</label>
          </div>
          <div>
            <input type="checkbox" id="biweekly" name="biweekly" />
            <label htmlFor="biweekly">{language.stepTwoMeetingFrequency2}</label>
          </div>
        </div>
        <i className="fal fa-horizontal-rule" />
        <div className="meeting-location-mobile">
          <div>
            <input type="checkbox" id="online" name="online" />
            <label htmlFor="online">{language.stepTwoMeetingLocation1}</label>
          </div>
          <div>
            <input type="checkbox" id="in-person" name="online" />
            <label htmlFor="in-person">{language.stepTwoMeetingLocation2}</label>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>{language.stepTwoChallengeLevel}</legend>
        <div className="challenge-level-mobile">
          <div>
            <input type="checkbox" id="little" name="challenge-level" value="little" />
            <label htmlFor="little">{language.stepTwoChallengeLevel1}</label>
          </div>
          <div>
            <input type="checkbox" id="moderate" name="challenge-level" value="moderate" />
            <label htmlFor="moderate">{language.stepTwoChallengeLevel2}</label>
          </div>
          <div>
            <input type="checkbox" id="lots" name="challenge-level" value="lots" />
            <label htmlFor="lots">{language.stepTwoChallengeLevel3}</label>
          </div>
        </div>
      </fieldset>
      <div className="choose-package-mobile">
        <div className="choose-package-top">
          <input
            className="hidden"
            id={`package-${WWM_PACKAGE_EXPRESS}`}
            name="package"
            required
            type="radio"
            value={WWM_PACKAGE_EXPRESS}
          />
          <label
            className={classNames('choose-package-option-mobile', 'choose-express-mobile', {
              selected: isExpressSelected,
              ['selection-made']: selectionMade,
            })}
            htmlFor={`package-${WWM_PACKAGE_EXPRESS}`}
            onClick={() => handleSelection(WWM_PACKAGE_EXPRESS)}
          >
            <div className="ribbon-mobile">
              <span>{language.packageExpress}</span>
            </div>
            <div className="package-info-mobile">
              <div>
                <div className="package-element-largest-mobile">
                  <div>{language.packageTotal6sessions}</div>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionZero}</div>
                  <Tooltip>
                    {language.tooltipInsight1} <br />
                    {language.tooltipInsight2} <br />
                    {language.tooltipInsight3} <br />
                    {language.tooltipInsight4} <br />
                    {language.tooltipInsight5} <br />
                  </Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionsRegular}</div>
                  <Tooltip>{language.tooltipSessions}</Tooltip>
                </div>
              </div>
              <br />
              <br />
              <div className="package-price-group-mobile circle-sketch-1-mobile">
                <div className="price-package-mobile">{language.packageExpressTotal}</div>
                <div className="price-session-mobile">
                  <div>{language.packageExpressSeparate}</div>
                  <Tooltip>
                    {language.tooltipPrices1} <br />
                    {language.tooltipPrices2} <br />
                    {language.tooltipPrices3} <br />
                    {language.tooltipPrices4} <br />
                  </Tooltip>
                </div>
              </div>
            </div>
          </label>
          <input
            className="hidden"
            id={`package-${WWM_PACKAGE_LITE}`}
            name="package"
            required
            type="radio"
            value={WWM_PACKAGE_LITE}
          />
          <label
            className={classNames('choose-package-option-mobile', 'choose-lite-mobile', {
              selected: isLiteSelected,
              ['selection-made']: selectionMade,
            })}
            htmlFor={`package-${WWM_PACKAGE_LITE}`}
            onClick={() => handleSelection(WWM_PACKAGE_LITE)}
          >
            <div className="ribbon-mobile">
              <span>{language.packageLite}</span>
            </div>
            <div className="package-info-mobile">
              <div>
                <div className="package-element-largest-mobile">
                  <div>{language.packageTotal10sessions}</div>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionZero}</div>
                  <Tooltip>
                    {language.tooltipInsight1} <br />
                    {language.tooltipInsight2} <br />
                    {language.tooltipInsight3} <br />
                    {language.tooltipInsight4} <br />
                    {language.tooltipInsight5} <br />
                  </Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessions9Sessions}</div>
                  <Tooltip>{language.tooltipSessions}</Tooltip>
                </div>
              </div>
              <br />
              <br />
              <div className="package-price-group-mobile circle-sketch-4-mobile">
                <div className="price-package-mobile">{language.packageLiteTotal}</div>
                <div className="price-session-mobile">
                  <div>{language.packageLiteSeparate}</div>
                  <Tooltip>
                    {language.tooltipPrices1} <br />
                    {language.tooltipPrices2} <br />
                    {language.tooltipPrices3} <br />
                    {language.tooltipPrices4} <br />
                  </Tooltip>
                </div>
              </div>
            </div>
          </label>
        </div>
        <div className="choose-package-bottom">
          <input
            className="hidden"
            id={`package-${WWM_PACKAGE_FULL}`}
            name="package"
            required
            type="radio"
            value={WWM_PACKAGE_FULL}
          />
          <label
            className={classNames('choose-package-option-mobile', 'choose-full-mobile', {
              selected: isFullSelected,
              ['selection-made']: selectionMade,
            })}
            htmlFor={`package-${WWM_PACKAGE_FULL}`}
            onClick={() => handleSelection(WWM_PACKAGE_FULL)}
          >
            <div className="ribbon-mobile">
              <span>{language.packageFull}</span>
            </div>
            <div className="package-info-mobile">
              <div>
                <div className="package-element-largest-mobile">
                  <div>{language.packageTotal6sessions}</div>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionZero}</div>
                  <Tooltip>
                    {language.tooltipInsight1} <br />
                    {language.tooltipInsight2} <br />
                    {language.tooltipInsight3} <br />
                    {language.tooltipInsight4} <br />
                    {language.tooltipInsight5} <br />
                  </Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessions4Sessions}</div>
                  <Tooltip>{language.tooltipSessions}</Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionClosing}</div>
                  <Tooltip>
                    {language.tooltipSessionClosing1} <br />
                    {language.tooltipSessionClosing2} <br />
                    {language.tooltipSessionClosing3} <br />
                  </Tooltip>
                </div>
                <div className="package-element-large-mobile">
                  <div>{language.packageTouchdown}</div>
                  <Tooltip>
                    {language.tooltipTouchdowns1} <br />
                    {language.tooltipTouchdowns2} <br />
                    {language.tooltipTouchdowns3} <br />
                    {language.tooltipTouchdowns4} <br />
                  </Tooltip>
                </div>
                <div className="package-element-large-mobile">
                  <div>{language.packageReports}</div>
                  <Tooltip>
                    {language.tooltipReports1} <br />
                    {language.tooltipReports2} <br />
                    {language.tooltipReports3} <br />
                  </Tooltip>
                </div>
              </div>
              <br />
              <br />
              <div className="package-price-group-mobile circle-sketch-3-mobile">
                <div className="price-package-mobile">{language.packageFullTotal}</div>
                <div className="price-session-mobile">
                  <div>{language.packageFullSeparate}</div>
                  <Tooltip>
                    {language.tooltipPrices1} <br />
                    {language.tooltipPrices2} <br />
                    {language.tooltipPrices3} <br />
                    {language.tooltipPrices4} <br />
                  </Tooltip>
                </div>
              </div>
            </div>
          </label>
          <input
            className="hidden"
            id={`package-${WWM_PACKAGE_BEYOND}`}
            name="package"
            required
            type="radio"
            value={WWM_PACKAGE_BEYOND}
          />
          <label
            className={classNames('choose-package-option-mobile', 'choose-beyond-mobile', {
              selected: isBeyondSelected,
              ['selection-made']: selectionMade,
            })}
            htmlFor={`package-${WWM_PACKAGE_BEYOND}`}
            onClick={() => handleSelection(WWM_PACKAGE_BEYOND)}
          >
            <div className="ribbon-mobile">
              <span>{language.packageBeyond}</span>
            </div>
            <div className="package-info-mobile">
              <div>
                <div className="package-element-largest-mobile">
                  <div>{language.packageTotal10sessions}</div>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionZero}</div>
                  <Tooltip>
                    {language.tooltipInsight1} <br />
                    {language.tooltipInsight2} <br />
                    {language.tooltipInsight3} <br />
                    {language.tooltipInsight4} <br />
                    {language.tooltipInsight5} <br />
                  </Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionsRegularPlus}</div>
                  <Tooltip>{language.tooltipSessions}</Tooltip>
                </div>
                <div className="package-element-small-mobile">
                  <div>{language.packageSessionClosing}</div>
                  <Tooltip>
                    {language.tooltipSessionClosing1} <br />
                    {language.tooltipSessionClosing2} <br />
                    {language.tooltipSessionClosing3} <br />
                  </Tooltip>
                </div>
                <div className="package-element-large-mobile">
                  <div>{language.packageTouchdown}</div>
                  <Tooltip>
                    {language.tooltipTouchdowns1} <br />
                    {language.tooltipTouchdowns2} <br />
                    {language.tooltipTouchdowns3} <br />
                    {language.tooltipTouchdowns4} <br />
                  </Tooltip>
                </div>
                <div className="package-element-large-mobile">
                  <div>{language.packageReports}</div>
                  <Tooltip>
                    {language.tooltipReports1} <br />
                    {language.tooltipReports2} <br />
                    {language.tooltipReports3} <br />
                  </Tooltip>
                </div>
              </div>
              <br />
              <br />
              <div className="package-price-group-mobile circle-sketch-2-mobile">
                <div className="price-package-mobile">{language.packageBeyondTotal}</div>
                <div className="price-session-mobile">
                  <div>{language.packageBeyondSeparate}</div>
                  <Tooltip>
                    {language.tooltipPrices1} <br />
                    {language.tooltipPrices2} <br />
                    {language.tooltipPrices3} <br />
                    {language.tooltipPrices4} <br />
                  </Tooltip>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default StepTwoMobile;
