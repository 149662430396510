import React, { useEffect, useState } from 'react';
import './Blog.mobile.scss';
import useLanguage from '../../languages/useLanguage';
import Page from '../../blocks/Page';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';
import useBlogLanguage from '../../languages/useBlogLanguage';
import getBlogPosts from './getBlogPosts';
import BlogCardLandscape from '../../blocks/BlogCardLandscape';

function BlogMobile() {
  const language = useLanguage();
  const blogLanguage = useBlogLanguage();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    setBlogPosts(getBlogPosts(blogLanguage));
  }, [blogLanguage]);

  return (
    <Page className="blog-page-mobile">
      <NavbarMobile />
      <div className="blog-page-content-mobile">
        <ContentBoxMobile side="right" title={language.blogTitle}>
          {blogPosts.map((blogPost, index) =>
            index % 2 === 0 ? (
              <BlogCardLandscape {...blogPost} key={index} />
            ) : (
              <BlogCardLandscape {...blogPost} key={index} side="right" />
            ),
          )}
        </ContentBoxMobile>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default BlogMobile;
