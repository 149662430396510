import React, { useEffect } from 'react';
import './ActionPlans.mobile.scss';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import useLanguage from '../../languages/useLanguage';
import BorderedImage from '../../blocks/BorderedImage';
import router, { ROUTE_WORK_WITH_ME } from '../../router';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';
import PropTypes from 'prop-types';
import { PLAN_FLOW_ELEMENT_ID, PLAN_PLAN_ELEMENT_ID, PLAN_RESILIENCE_ELEMENT_ID } from '../../constants';
import scrollToElement from '../../helpers/scrollToElement';
import Page from '../../blocks/Page';

function ActionPlansMobile({ jumpTo }) {
  const language = useLanguage();

  useEffect(
    function () {
      setTimeout(() => {
        if (jumpTo !== undefined) {
          const element = document.getElementById(jumpTo);
          console.log(jumpTo, element);
          scrollToElement(element);
        }
      }, 100);
    },
    [jumpTo],
  );

  return (
    <Page className="action-plans-page-mobile">
      <NavbarMobile />

      <div className="action-plans-page-content-mobile">
        <ContentBoxMobile id={PLAN_RESILIENCE_ELEMENT_ID} side="right" title={language.actionPlanResilience}>
          <div className="action-plans-resilience-mobile">
            <div className="outcomes-list-items-mobile">
              <div>{language.apResilienceOutcome1}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apResilienceOutcome2}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apResilienceOutcome3}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apResilienceOutcome4}</div>
            </div>
            <div className="resilience-image-mobile">
              <BorderedImage borderColor="#d4b466" image="/images/quote-find-resilience.jpg" />
            </div>
            <div className="resilience-text-content-mobile">
              <div>{language.apResilienceText1}</div>
              <div>{language.apResilienceText2}</div>
              <div>{language.apResilienceText3}</div>
              <div>{language.apResilienceText4}</div>
              <div>{language.apResilienceText5}</div>
            </div>
            <div className="resilience-conclusion-mobile" onClick={() => router.navigate(ROUTE_WORK_WITH_ME)}>
              <div>{language.apResilienceConclusion1}</div>
              <div>{language.apResilienceConclusion2}</div>
            </div>
          </div>
        </ContentBoxMobile>

        <ContentBoxMobile id={PLAN_PLAN_ELEMENT_ID} side="left" title={language.actionPlanHeadAhead}>
          <div className="action-plans-head-ahead-mobile">
            <div className="outcomes-list-items-mobile align-right">
              <div>{language.apHeadAheadOutcome1}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apHeadAheadOutcome2}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apHeadAheadOutcome3}</div>
              <i className="fal fa-horizontal-rule" />
              <div>{language.apHeadAheadOutcome4}</div>
            </div>
            <div className="head-ahead-image-mobile">
              <BorderedImage borderColor="#d4b466" image="/images/quote-head-ahead.jpg" />
            </div>
            <div className="head-ahead-text-content-mobile">
              <div>{language.apHeadAheadText1}</div>
              <div>{language.apHeadAheadText2}</div>
              <div>{language.apHeadAheadText3}</div>
              <div>{language.apHeadAheadText4}</div>
              <div>{language.apHeadAheadText5}</div>
            </div>
            <div className="head-ahead-conclusion-mobile" onClick={() => router.navigate(ROUTE_WORK_WITH_ME)}>
              <div>{language.apHeadAheadConclusion}</div>
            </div>
          </div>
        </ContentBoxMobile>

        <ContentBoxMobile id={PLAN_FLOW_ELEMENT_ID} side="right" title={language.actionPlanFlow}>
          <div className="action-plans-flow-mobile">
            <div className="outcomes-list-items-mobile">
              <div>{language.apFlowOutcome1}</div>
            </div>
            <div className="flow-image-mobile">
              <BorderedImage borderColor="#d4b466" image="/images/quote-follow-your-flow.jpg" />
            </div>
            <div className="flow-text-content-mobile">
              <div>{language.apFlowText1}</div>
              <div>{language.apFlowText2}</div>
              <div>{language.apFlowText3}</div>
              <div>{language.apFlowText4}</div>
            </div>
            <div className="flow-conclusion-mobile" onClick={() => router.navigate(ROUTE_WORK_WITH_ME)}>
              <div>{language.apFlowConclusion1}</div>
              <div>{language.apFlowConclusion2}</div>
            </div>
          </div>
        </ContentBoxMobile>
      </div>

      <FooterMobile />
    </Page>
  );
}

ActionPlansMobile.propTypes = {
  jumpTo: PropTypes.string,
};

export default ActionPlansMobile;
