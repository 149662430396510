import React from 'react';
import './AboutCoaching.scss';
import useLanguage from '../../../languages/useLanguage';
import BorderedImage from '../../../blocks/BorderedImage';

function AboutCoaching() {
  const language = useLanguage();

  return (
    <div className="about-coaching">
      <div className="about-coaching-content">
        <div className="about-coaching-image">
          <BorderedImage borderColor="#d4b466" image="/images/why-coaching-04.png" />
        </div>
        <div className="introduction">
          <div>{language.aboutCoachingBody1}</div>
          <div>{language.aboutCoachingBody2}</div>
          <div>{language.aboutCoachingBody3}</div>
          <div>{language.aboutCoachingBody4}</div>
          <div>{language.aboutCoachingBody5}</div>
        </div>
      </div>
      <div className="coaching-expectations">
        <div className="title">{language.aboutCoachingBody6}</div>
        <div className="pragmatism">
          <div className="subtitle">{language.aboutCoachingBody7}</div>
          <div className="text">{language.aboutCoachingBody8}</div>
        </div>
        <div className="no-agenda">
          <div className="subtitle">{language.aboutCoachingBody9}</div>
          <div className="text">{language.aboutCoachingBody10}</div>
        </div>
        <div className="listening">
          <div className="subtitle">{language.aboutCoachingBody11}</div>
          <div className="text">{language.aboutCoachingBody12}</div>
        </div>
      </div>
    </div>
  );
}

export default AboutCoaching;
