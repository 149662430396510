import { _001_POST, _002_POST, _003_POST, _004_POST } from './getBlogPost';

const getBlogPosts = (blogLanguage) => [
  {
    date: blogLanguage._004_Date,
    description: blogLanguage._004_CardDescription,
    image: '/images/blog-post-004-03.png',
    length: '10 mins',
    path: _004_POST,
    title: blogLanguage._004_Title,
    topic: blogLanguage.topicSelfLeadership,
  },

  {
    date: blogLanguage._001_Date,
    description: blogLanguage._001_CardDescription,
    image: '/images/blog-post-001-01.jpg',
    length: '7 mins',
    path: _001_POST,
    title: blogLanguage._001_Title,
    topic: blogLanguage.topicTips,
  },

  {
    date: blogLanguage._002_Date,
    description: blogLanguage._002_CardDescription,
    image: '/images/blog-post-002-03.jpg',
    length: '10 mins',
    path: _002_POST,
    title: blogLanguage._002_Title,
    topic: blogLanguage.topicLearning,
  },

  {
    date: blogLanguage._003_Date,
    description: blogLanguage._003_CardDescription,
    image: '/images/blog-post-003-04.jpg',
    length: '11 mins',
    path: _003_POST,
    title: blogLanguage._003_Title,
    topic: blogLanguage.topicSelfLeadership,
  },
];

export default getBlogPosts;
