import React from 'react';
import PropTypes from 'prop-types';
import './Hoverable.scss';

function Hoverable(props) {
  const style = {};
  if (props.space === 'before' || props.space === 'both') {
    style.paddingLeft = '0.4rem';
  }
  if (props.space === 'after' || props.space === 'both') {
    style.paddingRight = '0.4rem';
  }
  return (
    <span className="hoverable" onClick={props.onClick} style={style}>
      {props.children}
    </span>
  );
}

Hoverable.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  space: PropTypes.oneOf(['before', 'after', 'both']),
};

export default Hoverable;
