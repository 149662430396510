import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';
import styled from 'styled-components';

const Icon = styled.i`
  font-size: 2vh;
`;

function Tooltip({ children }) {
  const [uuid] = useState(v4());
  const ref = useRef();

  return (
    <>
      <Icon className="fal fa-comment-dots" data-tip data-for={uuid} ref={ref} />
      <ReactTooltip clickable id={uuid} place="top" effect="solid" multiline scrollHide>
        {children}
      </ReactTooltip>
    </>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
