import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ContentBox.scss';
import { SCROLL_CONTENT_ELEMENT_ID } from '../constants';

function ContentBox({ children, id, side, title }) {
  const ref = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    document.getElementById(SCROLL_CONTENT_ELEMENT_ID)?.addEventListener('scroll', () => {
      const bounds = ref.current.getBoundingClientRect();
      const statusBarHeight = document.getElementById('navbar').offsetHeight;

      if (bounds.y < statusBarHeight) {
        if (titleRef.current.style.position === 'absolute') {
          titleRef.current.style.position = 'fixed';
          titleRef.current.style.top = `${bounds.y}px`;
          titleRef.current.style.width = `${bounds.width}px`;
        }
      } else {
        titleRef.current.style.position = 'absolute';
        titleRef.current.style.top = '0px';
        titleRef.current.style.width = '100%';
      }
    });
  }, []);

  return (
    <div className={`content-box ${side}`} id={id} ref={ref}>
      <div className="content-box-title" ref={titleRef}>
        <div>{title}</div>
      </div>
      <div className="content-box-content">{children}</div>
    </div>
  );
}

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string.isRequired,
};

export default ContentBox;
