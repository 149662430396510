import React from 'react';
import useBlogLanguage from '../../../languages/useBlogLanguage';
import SocialMedia from '../SocialMedia';

function _002_Post() {
  const blogLanguage = useBlogLanguage();

  return (
    <div className="blog-post-page">
      <div className="blog-post-banner">
        <img
          alt="Why everyone should work in hospitality at least once"
          className="blog-post-photo-banner"
          src="/images/blog-post-002-03.jpg"
        />
        <div className="blog-post-title">
          <div>{blogLanguage._002_Title1}</div>
          <div>{blogLanguage._002_Title2}</div>
        </div>
        <div className="banner-bottom-line">
          <div className="post-details">
            <div className="blog-post-date">{blogLanguage._002_Date}</div>
            <div> | </div>
            <div className="blog-topic">{blogLanguage.topicLearning}</div>
            <div> | </div>
            <div className="blog-reading-time">10 mins</div>
          </div>
          <SocialMedia />
        </div>
      </div>

      <div className="blog-post-content">
        <div className="blog-post-introduction">
          <div>{blogLanguage._002_Introduction1}</div>
          <div>{blogLanguage._002_Introduction2}</div>
          <div>{blogLanguage._002_Introduction3}</div>
        </div>
        <div className="blog-post-paragraphs">
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">1</div>
            <div className="blog-subtitle-text">{blogLanguage._002_PrioritiesTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_Priorities1}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Priorities2}</span>
              <span>{blogLanguage._002_Priorities3}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Priorities4}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Priorities5}</span>
              <span>{blogLanguage._002_Priorities6}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._002_Priorities7}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Priorities8}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Priorities9}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Priorities10}</span>
              <span>{blogLanguage._002_Priorities11}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Priorities12}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._002_Priorities13}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Priorities14}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Priorities15}</span>
              <span>{blogLanguage._002_Priorities16}</span>
            </div>
            <span>{blogLanguage._002_Priorities17}</span>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">2</div>
            <div className="blog-subtitle-text">{blogLanguage._002_GettingAlongTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_GettingAlong1}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong2}</span>
              <span className="highlighted-sketch">{blogLanguage._002_GettingAlong3}</span>
              <span>{blogLanguage._002_GettingAlong4}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong5}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_GettingAlong6}</span>
              <span>{blogLanguage._002_GettingAlong7}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._002_GettingAlong8}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong9}</span>
              <span className="highlighted-sketch">{blogLanguage._002_GettingAlong10}</span>
              <span>{blogLanguage._002_GettingAlong11}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong12}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_GettingAlong13}</span>
              <span>{blogLanguage._002_GettingAlong14}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong15}</span>
            </div>
            <div>
              <span>{blogLanguage._002_GettingAlong16}</span>
              <span className="highlighted-sketch">{blogLanguage._002_GettingAlong17}</span>
              <span>{blogLanguage._002_GettingAlong18}</span>
            </div>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">3</div>
            <div className="blog-subtitle-text">{blogLanguage._002_HumilityTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_Humility1}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility2}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Humility3}</span>
              <span>{blogLanguage._002_Humility4}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility5}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility6}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Humility7}</span>
              <span>{blogLanguage._002_Humility8}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility9}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Humility10}</span>
              <span>{blogLanguage._002_Humility11}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._002_Humility12}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility13}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Humility14}</span>
              <span>{blogLanguage._002_Humility15}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Humility16}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Humility17}</span>
              <span>{blogLanguage._002_Humility18}</span>
            </div>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">4</div>
            <div className="blog-subtitle-text">{blogLanguage._002_EmpathyTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_Empathy1}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Empathy2}</span>
              <span>{blogLanguage._002_Empathy3}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Empathy4}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Empathy5}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Empathy6}</span>
              <span>{blogLanguage._002_Empathy7}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Empathy8}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Empathy9}</span>
              <span>{blogLanguage._002_Empathy10}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Empathy11}</span>
            </div>
            <div className="important-sentence">
              <span>{blogLanguage._002_Empathy12}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Empathy13}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Empathy14}</span>
              <span>{blogLanguage._002_Empathy15}</span>
            </div>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">5</div>
            <div className="blog-subtitle-text">{blogLanguage._002_CommunicationTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_Communication1}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Communication2}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Communication3}</span>
              <span>{blogLanguage._002_Communication4}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Communication5}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Communication6}</span>
              <span>{blogLanguage._002_Communication7}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Communication8}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Communication9}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Communication10}</span>
              <span>{blogLanguage._002_Communication11}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_Communication12}</span>
              <span>{blogLanguage._002_Communication13}</span>
            </div>
            <div>
              <span>{blogLanguage._002_Communication14}</span>
              <span className="highlighted-sketch">{blogLanguage._002_Communication15}</span>
              <span>{blogLanguage._002_Communication16}</span>
            </div>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">6</div>
            <div className="blog-subtitle-text">{blogLanguage._002_TeamSpiritTitle}</div>
          </div>
          <div>
            <div>
              <span>{blogLanguage._002_TeamSpirit1}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_TeamSpirit2}</span>
              <span>{blogLanguage._002_TeamSpirit3}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit4}</span>
              <span className="highlighted-sketch">{blogLanguage._002_TeamSpirit5}</span>
              <span>{blogLanguage._002_TeamSpirit6}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit7}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_TeamSpirit8}</span>
              <span>{blogLanguage._002_TeamSpirit9}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit10}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit11}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit12}</span>
              <span className="highlighted-sketch">{blogLanguage._002_TeamSpirit13}</span>
              <span>{blogLanguage._002_TeamSpirit14}</span>
            </div>
            <div>
              <span>{blogLanguage._002_TeamSpirit15}</span>
              <span className="highlighted-sketch-reverse">{blogLanguage._002_TeamSpirit16}</span>
              <span>{blogLanguage._002_TeamSpirit17}</span>
            </div>
          </div>
          <div className="blog-subtitle">
            <div className="blog-subtitle-numbered">7</div>
            <div className="blog-subtitle-text">{blogLanguage._002_ShitHappensTitle}</div>
          </div>
          <div>
            <span>{blogLanguage._002_ShitHappens1}</span>
            <span className="highlighted-sketch">{blogLanguage._002_ShitHappens2}</span>
            <span>{blogLanguage._002_ShitHappens3}</span>
          </div>
          <div className="important-sentence">
            <span>{blogLanguage._002_ShitHappens4}</span>
          </div>
          <div className="blog-bullet-point">
            <span>{blogLanguage._002_ShitHappens5a}</span>
          </div>
          <div className="blog-bullet-point">
            <span>{blogLanguage._002_ShitHappens5b}</span>
          </div>
          <div className="blog-bullet-point">
            <span>{blogLanguage._002_ShitHappens5c}</span>
          </div>
          <div>
            <span>{blogLanguage._002_ShitHappens6}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._002_ShitHappens7}</span>
            <span>{blogLanguage._002_ShitHappens8}</span>
          </div>
          <div>
            <span>{blogLanguage._002_ShitHappens9}</span>
          </div>
          <div>
            <span>{blogLanguage._002_ShitHappens10}</span>
            <span className="highlighted-sketch">{blogLanguage._002_ShitHappens11}</span>
            <span>{blogLanguage._002_ShitHappens12}</span>
          </div>
          <div>
            <span>{blogLanguage._002_ShitHappens13}</span>
            <span className="highlighted-sketch-reverse">{blogLanguage._002_ShitHappens14}</span>
            <span>{blogLanguage._002_ShitHappens15}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default _002_Post;
