import React, { useState } from 'react';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import useLanguage from '../../languages/useLanguage';
import StepOneMobile from './sections-mobile/StepOne.mobile';
import StepTwoMobile from './sections-mobile/StepTwo.mobile';
import StepThreeMobile from './sections-mobile/StepThree.mobile';
import ButtonStartMobile from './sections-mobile/ButtonStart.mobile';
import ButtonEndMobile from './sections-mobile/ButtonEnd.mobile';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';
import Page from '../../blocks/Page';
import usePost from '../../hookers/usePost';

function WorkWithMeMobile() {
  const language = useLanguage();
  const postWorkWithMeForm = usePost('/api/work-with-me/');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (event) => {
    const formData = new FormData(document.getElementById('work-with-me-form'));
    const formJson = [...formData.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    event.preventDefault();
    console.log('submitted', formJson);
    postWorkWithMeForm(formJson);
    setFormSubmitted(true);
  };

  return (
    <Page className="work-with-me-page-mobile">
      <NavbarMobile />
      <div className="work-with-me-content-mobile">
        <ContentBoxMobile side="right" title={language.workWithMeTitle}>
          <form id="work-with-me-form" onSubmit={handleSubmit}>
            <ButtonStartMobile />
            <StepOneMobile />
            <StepTwoMobile />
            <StepThreeMobile />
            {formSubmitted && <ButtonEndMobile />}
          </form>
        </ContentBoxMobile>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default WorkWithMeMobile;
