import React from 'react';
import './PriceList.mobile.scss';
import useLanguage from '../../languages/useLanguage';
import NavbarMobile from '../../blocks/Navbar.mobile';
import FooterMobile from '../../blocks/Footer.mobile';
import Page from '../../blocks/Page';
import Tooltip from '../work-with-me/forms/Tooltip';
import ContentBoxMobile from '../../blocks/ContentBox.mobile';

function PriceListMobile() {
  const language = useLanguage();

  return (
    <Page className="price-list-page-mobile">
      <NavbarMobile />
      <div className="price-list-content-mobile">
        <ContentBoxMobile side="right" title={language.pricesTitle}>
          <div className="prices-introduction-mobile">
            <div>{language.pricesIntroduction1}</div>
            <div>{language.pricesIntroduction2}</div>
          </div>

          <div className="packages-mobile">
            <div className="choose-package-top">
              <div className="choose-package-option-mobile package-express">
                <div className="ribbon-mobile">
                  <span>{language.packageExpress}</span>
                </div>
                <div className="package-info-mobile">
                  <div>
                    <div className="package-element-largest-mobile">
                      <div>{language.packageTotal6sessions}</div>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionsRegular}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="package-price-group-mobile circle-sketch-1-mobile">
                    <div className="price-package-mobile">{language.packageExpressTotal}</div>
                    <div className="price-session-mobile">
                      <div>{language.packageExpressSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-package-option-mobile package-lite">
                <div className="ribbon-mobile">
                  <span>{language.packageLite}</span>
                </div>
                <div className="package-info-mobile">
                  <div>
                    <div className="package-element-largest-mobile">
                      <div>{language.packageTotal10sessions}</div>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessions9Sessions}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="package-price-group-mobile circle-sketch-4-mobile">
                    <div className="price-package-mobile">{language.packageLiteTotal}</div>
                    <div className="price-session-mobile">
                      <div>{language.packageLiteSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="choose-package-bottom">
              <div className="choose-package-option-mobile package-full">
                <div className="ribbon-mobile">
                  <span>{language.packageFull}</span>
                </div>
                <div className="package-info-mobile">
                  <div>
                    <div className="package-element-largest-mobile">
                      <div>{language.packageTotal6sessions}</div>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessions4Sessions}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionClosing}</div>
                      <Tooltip>
                        {language.tooltipSessionClosing1} <br />
                        {language.tooltipSessionClosing2} <br />
                        {language.tooltipSessionClosing3} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large-mobile">
                      <div>{language.packageTouchdown}</div>
                      <Tooltip>
                        {language.tooltipTouchdowns1} <br />
                        {language.tooltipTouchdowns2} <br />
                        {language.tooltipTouchdowns3} <br />
                        {language.tooltipTouchdowns4} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large-mobile">
                      <div>{language.packageReports}</div>
                      <Tooltip>
                        {language.tooltipReports1} <br />
                        {language.tooltipReports2} <br />
                        {language.tooltipReports3} <br />
                      </Tooltip>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="package-price-group-mobile circle-sketch-3-mobile">
                    <div className="price-package-mobile">{language.packageFullTotal}</div>
                    <div className="price-session-mobile">
                      <div>{language.packageFullSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="choose-package-option-mobile package-beyond">
                <div className="ribbon-mobile">
                  <span>{language.packageBeyond}</span>
                </div>
                <div className="package-info-mobile">
                  <div>
                    <div className="package-element-largest-mobile">
                      <div>{language.packageTotal10sessions}</div>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionZero}</div>
                      <Tooltip>
                        {language.tooltipInsight1} <br />
                        {language.tooltipInsight2} <br />
                        {language.tooltipInsight3} <br />
                        {language.tooltipInsight4} <br />
                        {language.tooltipInsight5} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionsRegularPlus}</div>
                      <Tooltip>{language.tooltipSessions}</Tooltip>
                    </div>
                    <div className="package-element-small-mobile">
                      <div>{language.packageSessionClosing}</div>
                      <Tooltip>
                        {language.tooltipSessionClosing1} <br />
                        {language.tooltipSessionClosing2} <br />
                        {language.tooltipSessionClosing3} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large-mobile">
                      <div>{language.packageTouchdown}</div>
                      <Tooltip>
                        {language.tooltipTouchdowns1} <br />
                        {language.tooltipTouchdowns2} <br />
                        {language.tooltipTouchdowns3} <br />
                        {language.tooltipTouchdowns4} <br />
                      </Tooltip>
                    </div>
                    <div className="package-element-large-mobile">
                      <div>{language.packageReports}</div>
                      <Tooltip>
                        {language.tooltipReports1} <br />
                        {language.tooltipReports2} <br />
                        {language.tooltipReports3} <br />
                      </Tooltip>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="package-price-group-mobile circle-sketch-2-mobile">
                    <div className="price-package-mobile">{language.packageBeyondTotal}</div>
                    <div className="price-session-mobile">
                      <div>{language.packageBeyondSeparate}</div>
                      <Tooltip>
                        {language.tooltipPrices1} <br />
                        {language.tooltipPrices2} <br />
                        {language.tooltipPrices3} <br />
                        {language.tooltipPrices4} <br />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="prices-faq-mobile">
            <div>
              <div className="question">{language.pricesFAQPackagesTitle}</div>
              <div className="answer">{language.pricesFAQPackages1}</div>
              <div className="answer">{language.pricesFAQPackages2}</div>
              <div className="answer">{language.pricesFAQPackages3}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQFrequencyTitle}</div>
              <div className="answer">{language.pricesFAQFrequency1}</div>
              <div className="answer">{language.pricesFAQFrequency2}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQBundleTitle}</div>
              <div className="answer">{language.pricesFAQBundle1}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQInstalmentsTitle}</div>
              <div className="answer">{language.pricesFAQInstalments1}</div>
            </div>
            <div>
              <div className="question">{language.pricesFAQDiscountsTitle}</div>
              <div className="answer">{language.pricesFAQDiscounts1}</div>
              <div className="answer">{language.pricesFAQDiscounts2}</div>
            </div>
          </div>
        </ContentBoxMobile>
      </div>
      <FooterMobile />
    </Page>
  );
}

export default PriceListMobile;
