import React from 'react';

function SocialMedia() {
  const href = document.location.href;
  const url = encodeURIComponent(href);

  const postTitle = document.getElementsByClassName('blog-post-title')[0]?.textContent ?? 'Akimi Space';
  const title = encodeURIComponent(postTitle);

  const emailSubject = encodeURIComponent('Akimi Space New Blog Post');
  const emailBody = encodeURIComponent(
    `I thought you might be interested in this blog post!\n\nHere is the link ${href}.\n\n`,
  );

  return (
    <div className="blog-post-share-options">
      <i className="fal fa-share-alt share-icon" />
      <a href={`mailto:?subject=${emailSubject}&body=${emailBody}`} rel="noopener noreferrer" target="blank">
        <i className="fas fa-envelope-square" />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite?url=${url}&title=${title}`}
        rel="noopener noreferrer"
        target="blank"
      >
        <i className="fab fa-linkedin" />
      </a>
      <a href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`} rel="noopener noreferrer" target="blank">
        <i className="fab fa-twitter-square" />
      </a>
      <a href={`https://www.facebook.com/sharer.php?display=popup&u=${url}`} rel="noopener noreferrer" target="blank">
        <i className="fab fa-facebook-square" />
      </a>
      <a
        href={`http://pinterest.com/pin/create/button/?url=${url}&description=${title}`}
        rel="noopener noreferrer"
        target="blank"
      >
        <i className="fab fa-pinterest-square" />
      </a>
      <a
        data-action="share/whatsapp/share"
        href={`https://api.whatsapp.com/send?text=${emailBody}`}
        rel="noopener noreferrer"
        target="blank"
      >
        <i className="fab fa-whatsapp-square" />
      </a>
    </div>
  );
}

export default SocialMedia;
