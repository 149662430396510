import React, { useState } from 'react';
import './Navbar.mobile.scss';
import useLanguage from '../languages/useLanguage';
import useSetLanguage from '../languages/useSetLanguage';
import useActiveLanguage from '../languages/useActiveLanguage';
import { useRouter } from 'react-router5';
import classNames from 'classnames';
import { NAVBAR_ELEMENT_ID } from '../constants';
import {
  ROUTE_ABOUT,
  ROUTE_ACTION_PLANS,
  ROUTE_BLOG,
  ROUTE_HOMEPAGE,
  ROUTE_PRICES,
  ROUTE_TESTIMONIALS,
  ROUTE_WORK_WITH_ME,
} from '../router';

function NavbarMobile() {
  const language = useLanguage();
  const setLanguage = useSetLanguage();
  const activeLanguage = useActiveLanguage();
  const router = useRouter();
  const [navExpanded, setNavExpanded] = useState(false);

  function handleEnglishClick() {
    setLanguage('English');
  }

  function handleFrenchClick() {
    setLanguage('French');
  }

  const englishClassName = activeLanguage === 'English' ? '' : 'disabled';
  const frenchClassName = activeLanguage === 'French' ? '' : 'disabled';

  return (
    <nav className={classNames('navbar-mobile', { expanded: navExpanded })} id={NAVBAR_ELEMENT_ID}>
      <i className="fad fa-bars" onClick={() => setNavExpanded(!navExpanded)} />
      <div className="navbar-logo">
        <img alt="logo akimi white" onClick={() => router.navigate(ROUTE_HOMEPAGE)} src="/images/logo-akimi-long.png" />
      </div>
      <div className="navbar-languages">
        <img alt="English" className={englishClassName} onClick={handleEnglishClick} src="/images/flag-english.png" />
        <img alt="French" className={frenchClassName} onClick={handleFrenchClick} src="/images/flag-french.png" />
      </div>
      <div className="navbar-item-list">
        <div className="content">
          <div onClick={() => router.navigate(ROUTE_WORK_WITH_ME)}>{language.navbarWork}</div>
          <div onClick={() => router.navigate(ROUTE_ACTION_PLANS)}>{language.navbarActionPlans}</div>
          <div onClick={() => router.navigate(ROUTE_PRICES)}>{language.navbarPrices}</div>
          <div onClick={() => router.navigate(ROUTE_ABOUT)}>{language.navbarAbout}</div>
          <div onClick={() => router.navigate(ROUTE_TESTIMONIALS)}>{language.navbarTestimonials}</div>
          <div onClick={() => router.navigate(ROUTE_BLOG)}>{language.navbarBlog}</div>
        </div>
        <div className="overlay" onClick={() => setNavExpanded(false)} />
      </div>
    </nav>
  );
}

export default NavbarMobile;
