import React, { useEffect, useState } from 'react';
import './LandingPage.scss';
import useIsMobile from '../../hookers/useIsMobile';
import useSetLanguage from '../../languages/useSetLanguage';
import { useRouter } from 'react-router5';
import classNames from 'classnames';
import { ROUTE_HOMEPAGE } from '../../router';

function LandingPage() {
  const router = useRouter();
  const isMobile = useIsMobile();
  const setLanguage = useSetLanguage();
  const [buttonless, setButtonless] = useState(true);
  const [unloading, setUnloading] = useState(false);

  useEffect(function () {
    setButtonless(false);
  }, []);

  useEffect(() => {
    if (unloading) {
      setButtonless(true);
      setTimeout(() => router.navigate(ROUTE_HOMEPAGE), 1500);
    }
  }, [router, unloading]);

  function handleEnglishClick() {
    setLanguage('English');
    setUnloading(true);
  }

  function handleFrenchClick() {
    setLanguage('French');
    setUnloading(true);
  }

  return (
    <div className={classNames('landing-page', { mobile: isMobile })}>
      <div className="text-content">
        <div className="buttons-content">
          <button className={classNames({ 'end-position': !buttonless })} onClick={handleEnglishClick}>
            English
          </button>
          <button className={classNames({ 'end-position': !buttonless })} onClick={handleFrenchClick}>
            Français
          </button>
        </div>
        <div className={classNames('title', { 'end-position': !buttonless })}>
          <img alt="logo akimi white" src="/images/logo-akimi-white-long.png" />
        </div>
      </div>
      {/* insert logo, minimise & make it move up left upon selection of language */}
      <div
        className={classNames('background-landscape', { 'end-position': buttonless, unloading })}
        style={{ backgroundImage: 'url("/images/landscape-01.jpg")' }}
      />
    </div>
  );
}

export default LandingPage;
