import React, { useEffect, useRef, useState } from 'react';
import './Modal.scss';
import useLanguage from '../languages/useLanguage';
import FlatButton from './FlatButton';
import useIsMobile from '../hookers/useIsMobile';
import { useRoute } from 'react-router5';
import { ROUTE_HOMEPAGE, ROUTE_WORK_WITH_ME } from '../router';

function Modal() {
  const route = useRoute();
  const language = useLanguage();
  const isMobile = useIsMobile();
  const routeRef = useRef(route.route?.name);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalCollapsed, setModalCollapsed] = useState(false);

  const routeName = route.route?.name;
  useEffect(() => {
    routeRef.current = routeName;
  }, [routeName]);

  useEffect(() => {
    if (!firstLoad) {
      setModalCollapsed(routeName !== ROUTE_HOMEPAGE);
      setModalVisible(routeName !== ROUTE_WORK_WITH_ME);
    }
  }, [firstLoad, routeName]);

  useEffect(() => {
    if (!firstLoad) return;

    if (routeRef.current === ROUTE_WORK_WITH_ME) {
      setFirstLoad(false);
    }

    if (routeRef.current !== ROUTE_WORK_WITH_ME) {
      setTimeout(function () {
        setFirstLoad(false);
        setModalVisible(true);
        setModalCollapsed(routeRef.current !== ROUTE_HOMEPAGE);
      }, 5_000);
    }
  }, [firstLoad]);

  function handleModalCollapseToggleClick() {
    setModalCollapsed(!isModalCollapsed);
  }

  let modalClassName = 'modal';
  if (isModalVisible) modalClassName += ' show';
  if (isModalCollapsed) modalClassName += ' collapsed';

  if (isMobile) {
    return null;
  }

  return (
    <div className={modalClassName}>
      <div className="modal-box">
        <i className="fal fa-arrow-to-right modal-collapse modal-toggle" onClick={handleModalCollapseToggleClick} />
        <i className="fal fa-arrow-from-right modal-expand modal-toggle" onClick={handleModalCollapseToggleClick} />
        <div className="modal-title">
          {language.modalCoffee}
          <i className="fal fa-mug-marshmallows" />
        </div>
        <div className="modal-text">{language.modalText}</div>
        <FlatButton className="work-with-me-button" label={language.modalButton} route="workWithMe" />
      </div>
    </div>
  );
}

export default Modal;
