import React from 'react';
import PropTypes from 'prop-types';
import BlogCardPortrait from '../../blocks/BlogCardPortrait';
import BlogCardLandscape from '../../blocks/BlogCardLandscape';

export const TOP_TO_BOTTOM = 'top-to-bottom';
export const LEFT_TO_RIGHT = 'left-to-right';
export const BOTTOM_TO_TOP = 'bottom-to-top';
export const RIGHT_TO_LEFT = 'right-to-left';

function OptionalBlogCard({ direction, blogInfo, gridArea }) {
  if (blogInfo === undefined) {
    return null;
  }

  if (direction === TOP_TO_BOTTOM) {
    return <BlogCardPortrait {...blogInfo} style={{ gridArea }} />;
  }

  if (direction === LEFT_TO_RIGHT) {
    return <BlogCardLandscape {...blogInfo} style={{ gridArea }} />;
  }

  if (direction === BOTTOM_TO_TOP) {
    return <BlogCardPortrait {...blogInfo} side="down" style={{ gridArea }} />;
  }

  if (direction === RIGHT_TO_LEFT) {
    return <BlogCardLandscape {...blogInfo} side="right" style={{ gridArea }} />;
  }

  return null;
}

OptionalBlogCard.propTypes = {
  blogInfo: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  direction: PropTypes.oneOf([TOP_TO_BOTTOM, LEFT_TO_RIGHT, BOTTOM_TO_TOP, RIGHT_TO_LEFT]),
  gridArea: PropTypes.string.isRequired,
};

export default OptionalBlogCard;
